import React, { useState } from "react";
import Footer from "components/Footer";
import Breadcrumb from "components/Breadcrumb";
import ButtonAddToCartRemoveAdd from "components/ButtonAddToCartRemoveAdd";
import ProductCartCardBox from "components/ProductCartCardBox";
import SliderProductBox from "components/ProductCartCardBox/sliderProductBox";
import { AddToMyOccasion } from "popups/AddToMyOccasion";
import { AddToMyOccasionAddNew } from "popups/AddToMyOccasionAddNew";
import { AddToCart } from "popups/AddToCart";
import { RepeatOrder } from "popups/RepeatOrder";
import { useTranslation } from "react-i18next";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import * as CUSTOM from "../../utils/helper/custom";
import * as APIS from "../../utils/helper/Enum";
import { getAllCategories } from "../../redux/reducers/allCategories";
import { useEffect, useRef } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Rating } from "@mui/material";
import { changeTrigger, currentTrigger } from "redux/reducers/trigger";
import {
  addDeleteGetLocalStorage,
  storageKeys,
} from "../../utils/global/localData";
import {
  Img,
  Line,
  List,
  PagerIndicator,
  Slider,
  Text,
  ProductImageZoomSlider,
  ThumbnailSlider,
} from "components";

// const useStyles = makeStyles((theme) => ({
//   customTooltip: {
//     backgroundColor: "white", // Change the background color here
//     color: "black", // Change the font color here
//     fontSize: "16px", // You can adjust the font size here if needed
//     boxShadow: "10px 14px 16px rgba(0, 0, 0, 0.1)",
//     padding: "20px",
//   },
// }));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    margin: 0,
    marginLeft: "-20px",
    padding: 0,
    boxShadow: "0 0 25px -5px rgb(0 0 0 / 0.1), 0 0 10px -6px rgb(0 0 0 / 0.1)",
    ".MuiTooltip-arrow": {
      color: "#FFFFFF", // Arrow color
      width: "28px", // Arrow width
      height: "16px", // Arrow height
      top: "-7px", // Position where arrow is pointing
      left: "12px!important", // Position where arrow is pointing
    },
  },
}));

const ProductDetailsroundshapePage = () => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const sliderRef = React.useRef(null);
  const tabbyRef = useRef(null);
  const dispatch = useDispatch();
  const loaderStatus = useSelector(currentTrigger);
  const [AddToCartOpen, setAddToCartOpen] = React.useState(false);
  const [sliderProductId, setSliderProductId] = useState(0);
  const allCategories = useSelector(getAllCategories);
  const [product, setProduct] = useState(null);
  const [cartData, setCartData] = useState([]);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [productRating, setProductRating] = useState([]);
  const [RepeatOrderOpen, setRepeatOrderOpen] = useState(false);
  const sliderState = React.useState(0)[0];
  const [thumbnailImages, setThumbnailImages] = React.useState([]);
  const [AddToMyOccasionOpen, setAddToMyOccasionOpen] = React.useState(false);
  const [AddToMyOccasionAddNewOpen, setAddToMyOccasionAddNewOpen] = React.useState(false);
  let [mainImageUrl, setMainImageUrl] = useState("");
  const setSelectedThumbnailIndex = useState(0)[1];
  const [activeUom, setActiveUom] = React.useState({});
  const [openTooltip, setOpenTooltip] = React.useState({
    status: false,
    id: 0,
  });
  const [incrementQty, setIncrementQty] = React.useState({
    id: 0,
    qty: 0,
    product_id: 0,
    uomData: {},
  });
  const [breadcrumbData, setBreadcrumbData] = React.useState({
    maincategoryname: "",
    subcatname: "",
  });

  const handleThumbnailClick = (index) => {
    setSelectedThumbnailIndex(index);
    setMainImageUrl(thumbnailImages[index]);
  };

  /**
   * get product details
   */
  const getProduct = async () => {
    try {
      dispatch(changeLoader(true));
      //user/api/v1/get-products?orderBy=&orderType=&page=&limit=&price=400-500&sub_category_id=1,2&category_id=1 isme
      let params = {
        slug: slug,
      };
      let response = await globalRequest(
        APIS?.PRODUCTS?.GET_PRODUCT_BY_ID,
        "get",
        {},
        { params: params },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setProduct(response?.data);
        let uomsList = response?.data?.productUoms;
        uomsList = uomsList.filter((item) => item?.isDefault == 1);
        if (uomsList?.length > 0) {
          setActiveUom(uomsList[0]);
          let imageUrls = uomsList[0]?.productUomImages?.map((item) =>
            CUSTOM.getImage(item?.image)
          );
          setThumbnailImages(imageUrls);
          mainImageUrl = imageUrls?.[0];
          setMainImageUrl(imageUrls?.[0]);
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    } catch (e) {}
    dispatch(changeLoader(false));
  };

  /**
   * get product details
   */
  const getRelatedProduct = async (id) => {
    try {
      dispatch(changeLoader(true));
      //user/api/v1/get-products?orderBy=&orderType=&page=&limit=&price=400-500&sub_category_id=1,2&category_id=1 isme
      let response = await globalRequest(
        `${APIS?.PRODUCTS?.GET_RELATED_PRODUCT}/${id}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setRelatedProduct(response?.data?.data);
      } else {
        setRelatedProduct([]);
      }
    } catch (e) {}
    dispatch(changeLoader(false));
  };

  /**
   * get product raiting details
   */
  const getProductRaiting = async (id) => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${APIS?.PRODUCTS?.GET_PRODUCT_RATING_BY_ID}/${id}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setProductRating(response?.data?.data);
      } else {
        setProductRating([]);
      }
    } catch (e) {}
    dispatch(changeLoader(false));
  };

  /**
   * set header breadcrumb
   */
  const setBreadcrumb = () => {
    if (product?.productCategories) {
      const mainCategory =
        product?.productCategories?.map((item) => item?.categoryId) || [];
      let matchedCategory = { main: "", sub: "" };
      for (const catItem of allCategories) {
        for (const subcatItem of catItem?.subcategories) {
          if (mainCategory.includes(catItem?.id)) {
            matchedCategory.main = CUSTOM.getdataByLangKey(
              catItem?.categoryLocales,
              "",
              "name"
            );
            matchedCategory.sub = CUSTOM.getdataByLangKey(
              subcatItem?.categoryLocales,
              "",
              "name"
            );
            break; // Stop the loop if a match is found
          }
        }
      }

      let LocalData = addDeleteGetLocalStorage(
        storageKeys?.SUBCATEGORY_NAME,
        {},
        "get"
      );
      if (LocalData) {
        LocalData = JSON.parse(LocalData);
        setBreadcrumbData({
          subcatname: CUSTOM.getdataByLangKey(
            product?.productLocales,
            "",
            "name"
          ),
          maincategoryname: LocalData?.name,
          maincategoryslug: LocalData?.slug,
        });
      } else {
        setBreadcrumbData({
          subcatname: CUSTOM.getdataByLangKey(
            product?.productLocales,
            "",
            "name"
          ),
          maincategoryname: "",
          maincategoryslug: "",
        });
      }
    }
  };

  /**
   * change uom
   */
  const changeUom = (id) => {
    let uoms = product?.productUoms;
    uoms = uoms.filter((item) => item?.id === id);
    if (uoms.length != 0) {
      setActiveUom(uoms?.length > 0 ? uoms[0] : {});
    }
  };

  /**
   * get featured image
   */
  const getFeaturedImage = (imgs) => {
    let featuredImage = imgs?.find((item) => item?.featured === "1");
    if (featuredImage) {
      return CUSTOM.getImage(featuredImage?.image);
    }
    return "";
  };

  const CheckUomExistInCart = (main_product_id) => {
    if (cartData.length > 0) {
      let data = cartData.filter(
        (items) => items?.productId == main_product_id
      );
      if (data.length > 0) {
        let quantityData = { ...data[data.length - 1], quantity: data.reduce((total, item) => {
          return total + item.quantity;
        }, 0)};
        return quantityData;
        // return data[data.length - 1];
      }
    }
  };

  /**
   *  Decreamment Count of cart item
   */
  const decrementCount = async (id, qtyCount, type = "subtract") => {
    //getCartItemID
    try {
      dispatch(changeLoader(true));
      let inputData = {
        cart_item_id: id,
        qty: type == "subtract" ? Number(qtyCount) - 1 : Number(qtyCount) + 1,
        gaData: CUSTOM.getGACookieValues()
      };
      let response = await globalRequest(
        APIS?.CART?.UPDATE_QTY,
        "put",
        inputData,
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        dispatch(changeTrigger("fetchCartCount"));
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "success",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

   /**
* get modifiers
*/
const getModifiers = async (id) => {
  try {
    let response = await globalRequest(
      `${APIS?.PRODUCTS?.GET_MODIFIER_BY_UOM_ID}/${id}`,
      "get",
      {},
      {},
      true
    );
    response = response?.data;
    if (response?.status == "SUCCESS") {
      return {
        status: "success",
        data: response?.data
      }
    } else {
      return {
        status: "success",
        data: [],
      }
    }
  } catch (e) {
    return {
      status: "error",
      message: e?.message,
    }
  }
};

/**
* get product uoms
*/
const getProductUoms = async (id) => {
  try {
    let response = await globalRequest(
      `${APIS?.PRODUCTS?.GET_UOMS_BY_PRODUCT_ID}/${id}`,
      "get",
      {},
      {},
      true
    );
    response = response?.data;
    if (response?.status == "SUCCESS") {
      return {
        status: "success",
        data: response?.data
      };
    } else {
      return {
        status: "success",
        data: [],
      };
    }
  } catch (e) {
    return {
      status: "error",
      message: e?.message,
    }
  }
};

/**
*  check product data
* */
const checkProductData = async (id, qty, main_product_id) => {
  let uomData = await getProductUoms(main_product_id);
  if (uomData?.status == "error") {
    dispatch(
      setSnackbar({
        snackbarOpen: true,
        snackbarMessage: uomData?.message,
        snackbarState: "error",
      })
    );
    return "error";
  }
  if (uomData?.data?.length <= 1) {
    let modifierData = await getModifiers(uomData?.data?.[0]?.id);
    if (modifierData?.status == "error") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: modifierData?.message,
          snackbarState: "error",
        })
      );
      return "error";
    }
    if (modifierData?.data?.length == 0) {
      decrementCount(id, qty, "add");
      return "incremented";
    }
  }
  return "open_popup";
}

  const changeQuy = async (id, type = "subtract") => {
    if (cartData.length > 0) {
      let data = cartData.filter((items) => items?.productId == id);
      if (type == "add") {
        let lastids = data[data.length - 1];
        let result = await checkProductData(lastids?.id, Number(lastids?.quantity), lastids?.productId);
        if (result != "open_popup") return;
        setIncrementQty({
          id: lastids?.id,
          qty: Number(lastids?.quantity),
          product_id: lastids?.productId,
          uomData: lastids,
        });
        setRepeatOrderOpen(true);
      } else {
        if (data.length > 1) {
          setOpenTooltip({ status: true, id: id });
        } else {
          decrementCount(data?.[0]?.id, data?.[0]?.quantity, type);
          setOpenTooltip({ status: false, id: 0 });
        }
      }
    }
  };

  const getOffPrice = () => {
    let offPrice = CUSTOM.getOff(
      activeUom?.actualPrice || "",
      activeUom?.sellingPrice || ""
    );
    if (offPrice) {
      return `(${offPrice} ${t("off")})`;
    }
    return "";
  };

  useEffect(() => {
    if (slug) {
      getProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (allCategories.length > 0 && product != null) {
      setBreadcrumb();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategories, product]);

  /**
   * set thumbnail and images
   */
  useEffect(() => {
    if (Object.keys(activeUom).length != 0) {
      let imageUrls = activeUom?.productUomImages?.map((item) =>
        CUSTOM.getImage(item?.image)
      );
      setThumbnailImages(imageUrls);
      setMainImageUrl(imageUrls?.[0]);
    }
  }, [activeUom]);

  /**
   * set cardData
   */
  useEffect(() => {
    setCartData(CUSTOM.getCartData());
  }, []);

  useEffect(() => {
    if (loaderStatus == "loadCartData") {
      setCartData(CUSTOM.getCartData());
    }
  }, [loaderStatus]);

  useEffect(() => {
    if (RepeatOrderOpen == "incQty") {
      setRepeatOrderOpen(false);
      decrementCount(incrementQty?.id, incrementQty?.qty, "add");
    }
    if (RepeatOrderOpen == "addNewProduct") {
      setRepeatOrderOpen(false);
      setAddToCartOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RepeatOrderOpen]);

  useEffect(() => {
    if (openTooltip?.status) {
      setTimeout(() => {
        setOpenTooltip({
          status: false,
          id: 0,
        });
      }, 2500);
    }
  }, [openTooltip?.status]);

  useEffect(() => {
    if (product?.id) {
      getRelatedProduct(product?.id);
      getProductRaiting(product?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (!AddToCartOpen) {
      setSliderProductId(0);
    }
  }, [AddToCartOpen]);

  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.src = "https://checkout.tabby.ai/tabby-promo.js";
    script.async = true;
    script.onload = () => {
      // Check if TabbyPromo is available on window
      if (window.TabbyPromo) {
        // Initialize TabbyPromo after the script is loaded
        new window.TabbyPromo({
          selector: '#tabbyPromo', // Required: The element selector where the promo will be placed
          currency: 'AED', // Required: Currency of your product. AED|SAR|KWD|BHD|QAR only supported
          price: '500.00', // Required: Price of the product. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD
          installmentsCount: 4, // Optional: For non-standard plans
          lang: 'en', // Optional: Language of snippet and popups
          source: 'product', // Optional: Snippet placement; `product` for product page and `cart` for cart page
          publicKey: 'PUBLIC_API_KEY', // Required: Store Public Key which identifies your account when communicating with Tabby
          merchantCode: 'YOUR_MERCHANT_CODE', // Required: Your merchant code
        });
      }
    };

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="bg-white-A700 flex flex-col font-nunitoregular items-center justify-start mx-auto w-full">
        <div className="flex flex-col items-center justify-start w-full max-w-[1110px] mx-auto">
          <Breadcrumb data={breadcrumbData} />
        </div>

        {product ? (
          <div className="flex flex-col items-center justify-start w-full">
            <div className="flex flex-row sm:flex-col font-nunitoregular gap-[30px] items-start justify-start max-w-[1110px] mt-[30px] mb-7 mx-auto md:px-5 w-full">
              <div className="flex md:flex-1 flex-col gap-[30px] items-start justify-start w-[49%] md:w-full">
                <div className="flex flex-row xs:flex-col gap-5 items-start justify-between w-full">
                  <div className="flex flex-col xs:flex-row xs:flex-wrap xs:justify-start gap-3 w-20 xs:w-full h-[356px] md:h-auto items-start justify-between w-auto xs:order-2">
                    {mainImageUrl ? (
                      <ThumbnailSlider
                        thumbnailImages={thumbnailImages}
                        onThumbnailClick={handleThumbnailClick}
                      />
                    ) : null}
                  </div>
                  <div className="h-[440px] relative w-[440px] sm:w-full xs:order-1 product-main-img">
                    <div className="w-full h-full cursor-pointer">
                      {mainImageUrl ? (
                        <ProductImageZoomSlider
                          imageUrl={mainImageUrl}
                          zoomProps={{ zoomStyle: { zoom: 4 } }} // Adjust zoom level as needed
                        />
                      ) : null}
                    </div>
                    <div className="absolute flex flex-col top-4 items-center justify-between left-[3%] rtl:left-[unset] rtl:right-[3%] w-1/4 md:w-auto">
                      {Number(product?.isRecommended) == 1 ? (
                        <div className="bg-red-50_01 font-nunitoregular min-w-[107px] py-1 rounded text-center text-gray-900_01 text-xs">
                          {t("recommended")}
                        </div>
                      ) : Number(product?.isBestseller) == 1 ? (
                        <div className="bg-red-50_01 font-nunitoregular min-w-[107px] py-1 rounded text-center text-gray-900_01 text-xs">
                          {t("bestseller")}
                        </div>
                      ) : (
                        <div className="bg-red-50_01"></div>
                      )}
                    </div>
                    {
                      !!product?.averageRating ? <div className="absolute flex flex-col bottom-4 items-center justify-between left-[3%] rtl:left-[unset] rtl:right-[3%] w-1/4 md:w-auto">
                        <div className="bg-red-50_01 flex items-center justify-center min-w-[105px] px-3 py-[7px] rounded">
                          <Img
                            className="h-[18px] ltr:mr-2 rtl:ml-2 w-[18px]"
                            src="/images/img_star.svg"
                            alt="star"
                          />
                          <div className="font-nunitomedium text-center text-gray-900_01 text-sm">
                            {product?.averageRating
                              ? product?.averageRating
                              : 0}{" "}
                            | {product?.ratingCount ? product?.ratingCount : 0}
                          </div>
                        </div>
                      </div> : null
                    }
                  </div>
                </div>
              </div>
              <div className="flex md:flex-1 flex-col items-start justify-start w-[49%] md:w-full">
                <Text
                  className="text-2xl md:text-[22px] text-black-900 sm:text-xl"
                  size="txtnunitoSemiBold24"
                >
                  {CUSTOM.getdataByLangKey(product?.productLocales, "", "name")}{" "}
                  - {activeUom?.name}
                </Text>                    
                <div className="flex flex-col font-nunitomedium gap-2 items-start justify-start mt-5 w-auto">
                  <div className="flex flex-row gap-1 items-start justify-start w-auto">
                    <Text
                      className="text-gray-700 text-sm w-auto"
                      size="txtnunitoMedium14Gray700"
                    >
                      {t("chooseVariantColon")}
                    </Text>
                    <Text
                      className="text-black-900 text-sm w-auto"
                      size="txtnunitoMedium14Black900"
                    >
                      {activeUom?.name}
                    </Text>
                  </div>
                  <div className="flex flex-row gap-3 items-start justify-start w-auto md:w-full">
                    {product?.productUoms?.map((items) => {
                      return (
                        <div
                          className="flex flex-col gap-2 items-center justify-center"
                          key={Math.random()}
                        >
                          <div
                            className="relative mt-2"
                            onClick={(e) => {
                              changeUom(items?.id);
                            }}
                          >
                            {activeUom?.id == items?.id ? (
                              <Img
                                src="/images/product-check.svg"
                                className="h-6 w-6 absolute -top-2 -right-2"
                                alt="check"
                              />
                            ) : null}
                            <Img
                              className="h-[72px] md:h-auto object-cover border border-2 overflow-hidden rounded-lg border-black-900 w-[72px] cursor-pointer"
                              src={getFeaturedImage(items?.productUomImages)}
                              alt="rectangle19161"
                            />
                          </div>
                          <Text className="text-black-900 text-xs w-auto font-nunitoregular">
                            {items?.name}
                          </Text>
                        </div>
                      );
                    })}

                    {/* <Img
                    className="h-[72px] md:h-auto object-cover rounded-lg w-[72px] cursor-pointer"
                    src="/images/img_rectangle19162.png"
                    alt="rectangle19162"
                  />
                  <Img
                    className="h-[72px] md:h-auto object-cover rounded-lg w-[72px] cursor-pointer"
                    src="/images/img_rectangle19163.png"
                    alt="rectangle19163"
                  /> */}
                  </div>
                </div>
                <div className="mt-4">
                  <div id="tabbyPromo" ref={tabbyRef}></div>
                </div>
                <div className="flex flex-row gap-3 items-center justify-start mt-4 w-auto">
                  <Text
                    className="text-2xl md:text-[22px] text-black-900 sm:text-xl w-auto"
                    size="txtnunitoSemiBold24"
                  >
                    {`${t("sar")} ${activeUom?.sellingPrice || ""}`}
                  </Text>
                  {activeUom?.actualPrice != activeUom?.sellingPrice ? (
                    <Text
                      className="line-through text-base text-gray-700 w-auto"
                      size="txtnunitoRegular16Gray700"
                    >
                      {activeUom?.actualPrice || ""}
                    </Text>
                  ) : null}

                  <Text
                    className="text-base text-red-A700 w-auto"
                    size="txtnunitoRegular16RedA700"
                  >
                    {activeUom?.actualPrice || "" ? <>{getOffPrice()}</> : ""}
                  </Text>
                </div>
                {!CheckUomExistInCart(product?.id) ? (
                  <ButtonAddToCartRemoveAdd
                    className="bg-white-A700 flex flex-1 flex-col items-center justify-start rounded-lg mt-[10px] w-full"
                    activeUom={activeUom}
                    buttonAddToCart
                    productId={product?.id}
                  />
                ) : null}

                {CheckUomExistInCart(product?.id) && (
                  <div className="border border-black-900 border-solid bg-black-900 flex flex-col items-center justify-center mt-5 rounded">
                    <div className="flex flex-row gap-2 items-center justify-center pl-4 sm:pr-5 pr-6 py-3 rounded w-full">
                      <HtmlTooltip
                        open={
                          openTooltip?.status && openTooltip?.id == product?.id
                        }
                        placement="bottom-start"
                        arrow
                        title={
                          <>
                            <div className="py-4 px-5 w-full">
                              <Text className="leading-5 text-black-900 text-sm w-full font-nunitomedium">
                                {t("multiple_customization")}
                              </Text>
                            </div>
                          </>
                        }
                      >
                        <div>
                          <Img
                            className="h-5 w-5 cursor-pointer"
                            src="/images/minus-white.svg"
                            alt="minus"
                            onClick={() => {
                              changeQuy(product?.id, "subtract");
                            }}
                          />
                        </div>
                      </HtmlTooltip>
                      {/* <Tooltip
                      classes={{ tooltip: classes.customTooltip }}
                      open={openTooltip}
                      onClose={handleTooltipClose}
                      title={t("multiple_customization")}
                      arrow
                      placement="bottom"
                    ></Tooltip> */}
                      <Text
                        className="text-base text-white-A700 text-center w-[100px]"
                        size="txtnunitoMedium16"
                      >
                        {CheckUomExistInCart(product?.id)?.quantity}
                      </Text>
                      <Img
                        className="h-5 w-5 cursor-pointer"
                        src="/images/add-white.svg"
                        alt="add"
                        onClick={() => {
                          changeQuy(product?.id, "add");
                        }}
                      />
                    </div>
                  </div>
                )}

                <Line className="bg-gray-300 h-px mt-8 w-full" />
                <Text
                  className="mt-[34px] text-base text-black-900 rtl:text-right w-full"
                  size="txtnunitoMedium16"
                >
                  {t("description")}
                </Text>
                <Text
                  className="discription-area leading-[24.00px] mt-3.5 text-gray-700 text-sm"
                  size="txtnunitoMedium14Gray700"
                  dangerouslySetInnerHTML={{
                    __html: CUSTOM.getdataByLangKey(
                      product?.productLocales,
                      "",
                      "description"
                    ),
                  }}
                ></Text>
              </div>
            </div>
            {relatedProduct?.length > 0 ? (
              <div className="bg-gray-50_02 flex flex-col items-center justify-start mt-20 p-[54px] md:px-10 sm:px-5 w-full">
                <div className="flex flex-col gap-[43px] items-center justify-start max-w-[1110px] mb-[5px] mx-auto w-full relative">
                  <div className="flex xs:flex-col flex-row md:gap-10 items-start justify-between w-full">
                    <div className="flex flex-col gap-3.5 items-start justify-start">
                      <Text
                        className="sm:text-4xl xs:text-2xl md:text-[38px] text-[40px] text-black-900"
                        size="txtnunitoBold40Black900"
                      >
                        {t("buyItWith")}
                      </Text>
                      <Text
                        className="text-base xs:text-sm text-black-900"
                        size="txtnunitoMedium16"
                      >
                        {t(
                          "weHaveCreatedACakeOptimizedOrderingExperienceForYouComma"
                        )}
                      </Text>
                    </div>
                    {relatedProduct?.length > 4 ? (
                      <Img
                        className="h-6 sm:mt-0 mt-[11px]"
                        src="/images/img_reply_black_900.svg"
                        alt="reply"
                      />
                    ) : null}
                  </div>

                  <List className="w-full" orientation="horizontal">
                    <div className="mx-[-15px] sm:mx-[-10px] indicator-hide">
                      {relatedProduct?.length < 5 ? (
                        <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-[30px]">
                          {relatedProduct?.map((products, npindex) => {
                            return (
                              <ProductCartCardBox
                                className="bg-white-A700 flex flex-1 flex-col items-center justify-start p-[16px] rounded-lg w-full"
                                productimage={CUSTOM.getImage(
                                  products?.productUoms?.[0]
                                    ?.productUomImages?.[0]?.image || ""
                                )}
                                productRatingBox={!!products?.averageRating}
                                productrating={`${
                                  products?.averageRating
                                    ? products?.averageRating
                                    : 0
                                } | ${
                                  products?.ratingCount
                                    ? products?.ratingCount
                                    : 0
                                }`}
                                isRecommended={products?.isRecommended}
                                isBestSeller={products?.isBestseller}
                                uomData={products?.productUoms?.[0]}
                                productName={`${CUSTOM?.getdataByLangKey(
                                  products?.productLocales,
                                  "",
                                  "name"
                                )} - ${products?.productUoms?.[0]?.name || ""}`}
                                discountedPrice={
                                  <>
                                    {t("sar")}{" "}
                                    {products?.productUoms?.[0]?.sellingPrice ||
                                      ""}
                                  </>
                                }
                                originalPrice={
                                  products?.productUoms?.[0]?.actualPrice || ""
                                }
                                buttonAddToCart
                                productId={products?.id}
                                slug={products?.slug}
                                cartData={cartData}
                              />
                            );
                          })}
                        </div>
                      ) : (
                        <Slider
                          indicators={true}
                          infinite={false}
                          arrows={false}
                          autoPlay={false}
                          disableButtonsControls={false}
                          mouseTracking={false}
                          autoPlayInterval={6000}
                          activeIndex={sliderState}
                          responsive={{
                            0: { items: 1 },
                            550: { items: 2 },
                            768: { items: 3 },
                            992: { items: 4 },
                          }}
                          onSlideChanged={(e) => {
                            //setsliderState(e?.item);
                          }}
                          activeSlideCSS="scale-[1.00] absolute"
                          ref={sliderRef}
                          className="h-[100%] relative w-[100%]"
                          items={relatedProduct?.map((products) => {
                            return (
                              <SliderProductBox
                                setAddToCartOpen={setAddToCartOpen}
                                setSliderProductId={setSliderProductId}
                                openTooltip={openTooltip}
                                CheckUomExistInCart={CheckUomExistInCart}
                                changeQuy={changeQuy}
                                setRepeatOrderOpen={setRepeatOrderOpen}
                                className="bg-white-A700 flex flex-1 flex-col items-center justify-start p-[16px] rounded-lg w-full"
                                productimage={CUSTOM.getImage(
                                  products?.productUoms?.[0]
                                    ?.productUomImages?.[0]?.image || ""
                                )}
                                productRatingBox
                                productrating={`${
                                  products?.averageRating
                                    ? products?.averageRating
                                    : 0
                                } | ${
                                  products?.ratingCount
                                    ? products?.ratingCount
                                    : 0
                                }`}
                                isRecommended={products?.isRecommended}
                                isBestSeller={products?.isBestseller}
                                uomData={products?.productUoms?.[0]}
                                productName={`${CUSTOM?.getdataByLangKey(
                                  products?.productLocales,
                                  "",
                                  "name"
                                )} - ${products?.productUoms?.[0]?.name || ""}`}
                                discountedPrice={
                                  <>
                                    {t("sar")}{" "}
                                    {products?.productUoms?.[0]?.sellingPrice ||
                                      ""}
                                  </>
                                }
                                originalPrice={
                                  products?.productUoms?.[0]?.actualPrice || ""
                                }
                                buttonAddToCart
                                productId={products?.id}
                                slug={products?.slug}
                                cartData={cartData}
                              />
                            );
                          })}
                          renderDotsItem={({ isActive }) => {
                            if (isActive) {
                              return (
                                <div className="inline-block cursor-pointer rounded-[50%] h-1.5 bg-white_A700 w-1.5 relative" />
                              );
                            }
                            return (
                              <div
                                className="inline-block cursor-pointer rounded-[50%] h-1.5 bg-white_A700_7e w-1.5 relative"
                                role="button"
                                tabIndex={0}
                              />
                            );
                          }}
                        />
                      )}

                      <PagerIndicator
                        className="flex gap-1 h-1.5 w-[26px] mt-[-25px] mx-auto"
                        count={4}
                        activeCss="inline-block cursor-pointer rounded-[50%] h-1.5 bg-white_A700 w-1.5 relative"
                        activeIndex={sliderState}
                        inactiveCss="inline-block cursor-pointer rounded-[50%] h-1.5 bg-gray-900_01 w-1.5 relative"
                        sliderRef={sliderRef}
                        selectedWrapperCss="inline-block"
                        unselectedWrapperCss="inline-block"
                      />
                    </div>
                  </List>
                </div>
              </div>
            ) : null}
            {productRating.length > 0 ? (
              <div className="flex flex-col font-nunitoregular items-start justify-start max-w-[1110px] mx-auto md:px-5 w-full">
                <div className="w-full mb-7 xs:mb-3">
                  <Text
                    className="mt-20 sm:text-4xl xs:text-2xl md:text-[38px] text-[40px] text-black-900 rtl:text-right"
                    size="txtnunitoBold40Black900"
                  >
                    {t("customerReviews")}
                  </Text>
                </div>

                {productRating.map((items, index) => {
                  return (
                    <>
                      <div className="flex flex-col items-center justify-start mt-5 w-[68%] md:w-full">
                        <div className="flex flex-col items-start justify-start w-full">
                          <div className="flex flex-row font-nunitomedium gap-2 items-center justify-start w-[19%] md:w-full">
                            <div className="flex items-start justify-start gap-[2px]">
                              <Rating
                                name="no-value"
                                value={items?.ratingStar}
                                precision={0.5}
                                readOnly
                              />
                            </div>
                            <Text
                              className="my-1 text-base text-black-900"
                              size="txtnunitoMedium16"
                            >
                              {items?.ratingStar}
                            </Text>
                          </div>
                          <Text
                            className="leading-[24.00px] mt-4 text-base xs:text-sm text-black-900 w-full rtl:text-right"
                            size="txtnunitoRegular16"
                          >
                            {items?.reviewNote}
                          </Text>
                          <Text
                            className="mt-3 text-gray-700 text-xs"
                            size="txtnunitoRegular12Gray700"
                          >
                            <span className="text-gray-700 font-nunitoregular text-left font-normal rtl:text-right">
                              {CUSTOM.capitalizeWord(
                                items?.user?.firstName +
                                  " " +
                                  items?.user?.lastName
                              )}
                            </span>{" "}
                            <span className="text-gray-700 font-nunitoregular text-left font-normal rtl:text-right">
                              |
                            </span>{" "}
                            <span className="text-gray-700 font-nunitoregular text-left font-normal rtl:text-right">
                              {CUSTOM.changeDateFormat(
                                items?.createdAt,
                                "dd/MM/yyyy"
                              )}
                            </span>
                          </Text>
                        </div>
                      </div>
                      {productRating.length - 1 != index ? (
                        <Line className="bg-gray-300 h-px max-w-[1110px] mt-5 mx-auto w-full" />
                      ) : null}
                    </>
                  );
                })}

                <div className="flex flex-col font-nunitomedium items-center justify-center mt-5 rounded w-auto">
                  <div className="common-pointer flex flex-col items-center justify-center pr-3 py-2 rounded w-auto mb-10">
                    {/* <Text className="txtnunitoMedium14 text-center text-pink-800 text-sm w-auto">
                      {" "}
                      {isExpanded2 ? "View less" : "View more"}
                      </Text> */}
                  </div>
                </div>
              </div>
            ) : null}

            <Footer className="flex font-nunitoregular items-center justify-center mt-[68px] md:px-5 w-full" />
          </div>
        ) : null}
      </div>

      {AddToCartOpen === true ? (
        <AddToCart
          closepopup={setAddToCartOpen}
          productId={sliderProductId ? sliderProductId : product?.id}
        />
      ) : null}

      {RepeatOrderOpen === true ? (
        <RepeatOrder
          closepopup={setRepeatOrderOpen}
          cartIds={incrementQty}
          cartData={cartData}
        />
      ) : null}

      {AddToMyOccasionOpen === true ? (
        <AddToMyOccasion closepopup={setAddToMyOccasionOpen} />
      ) : null}

      {AddToMyOccasionAddNewOpen === true ? (
        <AddToMyOccasionAddNew
          closepopup={setAddToMyOccasionAddNewOpen}
          modalTopIcon="/images/img_arrowleft_gray_900_03.svg"
          OccasionTitle={t("addNewOccasion")}
          OccasionParagraph={t("enterBelowDetailsToAddANewOccasionDot")}
        />
      ) : null}
    </>
  );
};

export default ProductDetailsroundshapePage;
