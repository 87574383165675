import React, { useEffect, useState } from "react";
import { Img, List, PagerIndicator, Slider, Text } from "components";
import Footer from "components/Footer";
import HomeBlog from "components/HomeBlog";
import { useNavigate } from "react-router-dom";

// Importing localization strings
import { useTranslation } from "react-i18next";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import * as CUSTOM from "../../utils/helper/custom";
import * as API from "../../utils/helper/Enum";
import { getAuth } from "../../redux/reducers/loginData";
import Breadcrumb from "components/Breadcrumb";
import { useParams } from "react-router-dom";

const BlogDetailPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const sliderRef = React.useRef(null);
  const [sliderState, setsliderState] = React.useState(0);
  const { slug } = useParams();
  const [blogDetails, setBlogDetails] = useState(null);
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  /**
   * get blog details
   */
  const getBlogDetails = async () => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        `${API?.BLOGS?.GET_DETAILS_BY_ID}/${slug}`,
        "get",
        {},
        { params: { user_id: auth?.id } },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setBlogDetails(response?.data?.blogDetails);
        setRelatedBlogs(response?.data?.relatedBlogs);
      }
    } catch (e) {}
    dispatch(changeLoader(false));
  };

  /**
   * like unline blog post
   */
  const likeBlog = async (id) => {
    if (auth?.id > 0) {
      try {
        let response = await globalRequest(
          `${API?.BLOGS?.LIKE_DISLIKE}`,
          "put",
          {id: id, gaData: CUSTOM.getGACookieValues()},
          {},
          true
        );
        response = response?.data;
        if (response?.status == "SUCCESS") {
          if (blogDetails?.id == id) {
            setBlogDetails({
              ...blogDetails,
              isLike: !blogDetails?.isLike ? true : false,
              likes: !blogDetails?.isLike
                ? Number(blogDetails?.likes) + 1
                : blogDetails?.likes - 1,
            });
          } else {
            setRelatedBlogs((prevBlogList) => {
              return prevBlogList.map((blog) => {
                if (blog.id === id) {
                  return {
                    ...blog,
                    isLike: !blog?.isLike ? true : false,
                    likes: !blog?.isLike
                      ? Number(blog?.likes) + 1
                      : blog?.likes - 1,
                  };
                }
                return blog;
              });
            });
          }
        }
      } catch (e) {}
    }
  };

  let titleBreadCrumb = {
    blogname: CUSTOM.getdataByLangKey(blogDetails?.blogLocales, "", "title"),
  };

  useEffect(() => {
    if (slug) {
      CUSTOM.scrollTopBody();
      getBlogDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return (
    <>
      {blogDetails ? (
        <div className="bg-white-A700 flex flex-col items-center justify-start mx-auto w-full">
          <div className="flex flex-col justify-start w-full">
            <div className="flex flex-col items-center justify-start w-full max-w-[1110px] mx-auto">
              <Breadcrumb data={titleBreadCrumb} />
            </div>
            <div className="relative flex sm:flex-col flex-row font-nunitoregular gap-[30px] items-start justify-start max-w-[1110px] mx-auto mt-[30px] md:px-4 w-full">
              <div className="flex flex-col items-start justify-start w-auto top-[30px] md:w-[40%] xs:w-full">
                <Img
                  className="w-[504px] md:h-auto md:w-full object-cover rounded-tl-lg rounded-tr-lg w-full"
                  src={blogDetails?.imageLink}
                  alt="rectangle17564"
                />
              </div>
              <div className="flex flex-1 flex-col items-start justify-start md:mt-0 w-full">
                <Text
                  className="leading-[32.00px] text-2xl md:text-[22px] text-black-900 sm:text-xl w-full rtl:text-right"
                  size="txtnunitoSemiBold24"
                >
                  {CUSTOM.getdataByLangKey(
                    blogDetails?.blogLocales,
                    "",
                    "title"
                  )}
                </Text>
                <div className="flex flex-row font-nunitoregular items-start rtl:flex-row-reverse justify-start mt-5 w-[38%] md:w-full">
                  <Img
                    className="h-6 md:h-auto object-cover w-6"
                    src="/images/img_notofire.png"
                    alt="notofire"
                  />
                  <Text
                    className="ltr:ml-1 rtl:mr-1 mt-0.5 text-black-900 text-sm"
                    size="txtnunitoRegular14Black900"
                  >
                    {CUSTOM.formatViewCount(blogDetails?.views)} {t("views")}
                  </Text>
                  <Text
                    className="ml-2 rtl:ml-0 rtl:mr-2 mt-[3px] text-gray-700 text-sm"
                    size="txtnunitoRegular14"
                  >
                    |
                  </Text>
                  <Img
                    className="h-6 ml-[9px] w-6 cursor-pointer"
                    src={
                      blogDetails?.isLike
                        ? `/images/img_mdiheart.svg`
                        : `/images/Wishlist.svg`
                    }
                    alt="mdiheart"
                    onClick={(e) => {
                      if (auth?.id) {
                        likeBlog(blogDetails?.id);
                      } else {
                        navigate("/login");
                      }
                    }}
                  />
                  <Text
                    className="ltr:ml-1 rtl:mr-1 mt-0.5 text-black-900 text-sm"
                    size="txtnunitoRegular14Black900"
                  >
                    {CUSTOM.formatViewCount(blogDetails?.likes)} {t("likes")}
                  </Text>
                </div>
                <Text
                  className="leading-[24.00px] mt-8 text-gray-700 text-sm w-full rtl:text-right"
                  size="txtnunitoRegular14"
                  dangerouslySetInnerHTML={{__html: CUSTOM.getdataByLangKey(
                    blogDetails?.blogLocales,
                    "",
                    "description"
                  )}}
                >
                </Text>
              </div>
            </div>
            {relatedBlogs?.length > 0 ? (
              <div className="bg-gray-50_02 flex flex-col font-nunitosemibold items-center justify-end mt-[107px] p-[60px] md:px-4 w-full">
                <div className="flex flex-col gap-[31px] items-center justify-start max-w-[1110px] mx-auto w-full">
                  <div className="flex flex-row md:gap-10 items-center justify-between w-full">
                    <Text
                      className="text-2xl md:text-[22px] text-black-900 sm:text-xl"
                      size="txtnunitoSemiBold24"
                    >
                      {t("relatedBlogs")}
                    </Text>
                    {/* <Img
                      className="h-6 mr-6 rtl:mr-0 rtl:ml-6 xs:mx-0 rtl:xs:mx-0"
                      src="/images/img_reply_black_900.svg"
                      alt="reply"
                    /> */}
                  </div>
                  <List className="w-full" orientation="horizontal">
                    <div className="indicator-hide blog-indicator">
                      {relatedBlogs.length < 5 ? (
                        <>
                          <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-[30px] ">
                            {relatedBlogs.map((items, index) => (
                              <div className="bg-white-A700 flex flex-1 flex-col items-center justify-start p-[16px] rounded-lg w-full"> 
                                  <HomeBlog
                                    keys={index}
                                    data={items}
                                    likeBlog={likeBlog}
                                    userimage={items?.imageLink}
                                    viewcount={CUSTOM.formatViewCount(
                                      items?.views
                                    )}
                                    likecount={CUSTOM.formatViewCount(
                                      items?.likes
                                    )}
                                    title={CUSTOM.getdataByLangKey(
                                      items?.blogLocales,
                                      "",
                                      "title"
                                    )}
                                    description={CUSTOM.getdataByLangKey(
                                      items?.blogLocales,
                                      "",
                                      "shortDescription"
                                    )}
                                  /> 
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <Slider
                            autoPlay
                            arrows={false}
                            disableButtonsControls={false}
                            autoPlayInterval={2000}
                            activeIndex={sliderState}
                            responsive={{
                              0: { items: 1 },
                              550: { items: 2 },
                              768: { items: 2 },
                              992: { items: 3 },
                              1050: { items: 4 },
                            }}
                            onSlideChanged={(e) => {
                              setsliderState(e?.item);
                            }}
                            ref={sliderRef}
                            className="flex gap-[30px] max-w-[1110px] mt-[0px] mx-auto -mx-2.5 xs:mx-0 w-full"
                            items={relatedBlogs.map((items, index) => ( 
                                <HomeBlog
                                  keys={index}
                                  data={items}
                                  likeBlog={likeBlog}
                                  userimage={items?.imageLink}
                                  viewcount={CUSTOM.formatViewCount(
                                    items?.views
                                  )}
                                  likecount={CUSTOM.formatViewCount(
                                    items?.likes
                                  )}
                                  title={CUSTOM.getdataByLangKey(
                                    items?.blogLocales,
                                    "",
                                    "title"
                                  )}
                                  description={CUSTOM.getdataByLangKey(
                                    items?.blogLocales,
                                    "",
                                    "shortDescription"
                                  )}
                                /> 
                            ))}
                            renderDotsItem={({ isActive }) => {
                              if (isActive) {
                                return (
                                  <div className="inline-block cursor-pointer h-2 bg-black-900 w-4 rounded" />
                                );
                              }
                              return (
                                <div
                                  className="inline-block cursor-pointer rounded-[50%] h-2 bg-gray-300 w-2"
                                  role="button"
                                  tabIndex={0}
                                />
                              );
                            }}
                          />
                          <PagerIndicator
                            className="flex h-2 justify-center mt-[50px] mx-auto w-20"
                            count={relatedBlogs.length}
                            activeCss="inline-block cursor-pointer h-2 bg-black-900 w-4 rounded"
                            activeIndex={sliderState}
                            inactiveCss="inline-block cursor-pointer rounded-[50%] h-2 bg-gray-300 w-2"
                            sliderRef={sliderRef}
                            selectedWrapperCss="inline-block md:ml-[0] mx-[4.00px] sm:ml-[0]"
                            unselectedWrapperCss="inline-block md:ml-[0] mx-[4.00px] sm:ml-[0]"
                          />
                        </>
                      )}
                    </div>
                  </List>
                </div>
              </div>
            ) : (
              <>
                <p className="mb-10"></p>
              </>
            )}

            <Footer className="flex font-nunitoregular items-center justify-center md:px-5 w-full" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BlogDetailPage;
