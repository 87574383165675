import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { addDeleteGetLocalStorage, storageKeys } from "../global/localData";
import jwt_decode from "jwt-decode";
//import moment from 'moment';
import { ONBOARDING_APIS } from "utils/helper/Enum";
//import  analytics  from "../../components/Segment"
import TagManager from "react-gtm-module";

/**
 * Encode and Decode string
 * @param {*} str
 * @param {*} type
 * @returns
 */
export const base64Encode = (str, type = "encode") => {
  // Using btoa() function
  if (type == "decode") {
    return atob(str);
  }
  return btoa(str);
};

/**
 * mobile number
 */
export const mobileWithoutDash = (mobileNo) => {
  if (mobileNo) {
    return replaceKeyValue(mobileNo, "-", "");
  }
};

/**
 * credit card exipiry date message
 */
export const explodeExipryDate = (expdate) => {
  if (expdate) {
    let month = Math.floor(expdate / 100);
    let year = expdate % 100;
    return `${month.toString().padStart(2, "0")}/${year
      .toString()
      .padStart(2, "0")}`;
  }
};

/**
 * credit card logo images
 */
export const cardTypeLogo = (type = "VISA") => {
  type = type ? type.toUpperCase() : "";
  let card = {
    MASTERCARD: "Mastercard.png",
    VISA: "visa.png",
    AMEX: "american.png",
    SADAD: "",
    NAPS: "",
    KNET: "",
    MADA: "mada.png",
    MEEZA: "meeza.png",
  };
  return card[type]
    ? `/images/credit-card/${card[type]}`
    : "/images/credit-card/visa.png";
};

/**
 * credit card logo images
 */
export const walletPaymentStatusLogo = (type = "") => {
  let card = {
    order: "/images/money-paid.svg",
    wallet: "/images/money-added.svg",
    credit: "/images/money-added.svg",
    debit: "/images/money-paid.svg",
    refund: "/images/money-paid.svg",
  };
  return card[type] ? card[type] : "";
};

/**
 * get all order status
 */
export const getAllOrderStatus = (type = "regular") => {
  const gift = [
    //"pending",
    "giftlinksentAt",
    //"approved",
    "placed",
    "recipient_submited",
    "confirmed",
    "processing",
    "under_qa",
    "ready_to_pick",
    "out_for_delivery",
    "delivered",
    "rejected",
    "no_show",
    "cancelled",
  ];

  const regular = [
    "confirmed",
    "processing",
    "under_qa",
    "ready_to_pick",
    "out_for_delivery",
    "delivered",
    "rejected",
    "no_show",
    "cancelled",
  ];

  const custom_cake = [
    "pending",
    "approved",
    "confirmed",
    "processing",
    "under_qa",
    "ready_to_pick",
    "out_for_delivery",
    "delivered",
    "rejected",
    "no_show",
    "cancelled",
  ];

  let status = {
    gift: gift,
    regular: regular,
    custom_cake: custom_cake,
  };
  return status[type];
};

/**
 * all status icon
 */
export const orderStatusImgages = (status) => {
  let imageArray = {
    pending: "/images/img_warning.svg",
    cancelled: "/images/img_info-red.svg",
    rejected: "/images/img_close-red.svg",
    no_show: "/images/img_close-red.svg",
  };
  return imageArray[status]
    ? imageArray[status]
    : "/images/img_check_circle.svg";
};

/**
 * status name
 */
export const statusByKey = (key, type = "") => {
  let obj = {
    order_request: type != "on" ? "orderrequest" : "orderrequestOn",
    placed: type != "on" ? "placed" : "placedOn",
    confirmed: type != "on" ? "confirmed" : "confirmedOn",
    processing: type != "on" ? "processing" : "processingOn",
    out_for_delivery: type != "on" ? "outForDelivery" : "outForDeliveryOn",
    delivered: type != "on" ? "delivered" : "deliveredOn",
    cancelled: type != "on" ? "cancelled" : "cancelledOn",
    rejected: type != "on" ? "rejected" : "rejectedOn",
    pending: type != "on" ? "pending" : "pendingOn",
    recipient_submited:
      type != "on" ? "recipientFilledForm" : "recipientSubmittedOn",
    giftlinksentAt: type != "on" ? "giftlinkSent" : "giftLinkSentOn",
    approved: type != "on" ? "approved" : "approvedOn",
    no_show: type != "on" ? "noShow" : "noShowOn",
    under_qa: type != "on" ? "underQa" : "underQaOn",
    ready_to_pick: type != "on" ? "readyToPick" : "readyToPickOn",
  };
  return obj[key];
};

/**
 * status order for order message
 */
export const statusMessageByKey = (key) => {
  let obj = {
    placed: "placedOn",
    confirmed: "confirmOn_message",
    recipient_submited: "recipient_message",
    giftlinksentAt: "giftLink_message",
    processing: "processing_message",
    out_for_delivery: "outForDelivery_message",
    delivered: "deliveredOn_message",
    cancelled: "cancelledOn_message",
    rejected: "rejected_message",
    pending: "pending_message",
    approved: "approved_message",
    under_qa: "under_qa_message",
    no_show: "no_show_message",
    ready_to_pick: "ready_to_pick_message",
  };
  return obj[key];
};

/**
 * check inactive or delete product in carts
 */
export const findInactiveOrDelete = (cartData, type = "") => {
  if (type == "item") {
    if (
      cartData?.product?.status !== "active" ||
      cartData?.productUom.status !== "active"
    ) {
      return true;
    }
    if (
      cartData?.productUom?.stock == "inactive" ||
      cartData?.productUom?.isOutOfStock == true
    ) {
      return true;
    }
    if (cartData?.cartModifiers.length > 0) {
      return cartData.cartModifiers.some((nitems) => {
        return nitems.cartModifierItems.some((nitem) => {
          return nitem?.productUomModifierItem?.status !== "active";
        });
      });
    }
    return false;
  }
  return cartData.some((items) => {
    if (
      items?.product?.status !== "active" ||
      items?.productUom.status !== "active"
    ) {
      return true;
    }
    if (
      items?.productUom?.stock == "inactive" ||
      items?.productUom?.isOutOfStock == true
    ) {
      return true;
    }
    if (items?.cartModifiers.length > 0) {
      return items.cartModifiers.some((nitems) => {
        return nitems.cartModifierItems.some((nitem) => {
          return nitem?.productUomModifierItem?.status !== "active";
        });
      });
    }
    return false;
  });
};

/**
 * get last status data
 */
export const getLastStatusData = (orderStatusLogs, status = "") => {
  let data = orderStatusLogs.find((items) => items?.status == status);
  return data ? data : {};
};

export const getLastGiftStatusData = (giftStatusLogs, status = "") => {
  let data = giftStatusLogs.find((items) => items?.status == status);
  return data ? data : {};
};

/**
 * find by status logs
 */
export const getDateFromStatusLog = (
  orderStatusLogs,
  status = "",
  exist = ""
) => {
  if (orderStatusLogs) {
    let data = orderStatusLogs.find((items) => items?.status == status);
    if (exist) {
      if (data) {
        return true;
      } else {
        return false;
      }
    }
    return data?.date;
  }
};

export const getDateFromGiftStatusLog = (giftStatusLogs, statusKey) => {
  if (giftStatusLogs) {
    const data = giftStatusLogs.find((item) => item.status === statusKey);
    return data ? data.date : null;
  }
  return null;
};

export const mergeAndSortStatuses = ({
  orderStatuses = [],
  giftStatuses = [],
  allStatusesList = [],
  statusesToIgnore = [],
  type,
  giftType,
  t,
  statusByKey,
  statusMessageByKey,
  convertDateString,
  getDateFromGiftStatusLog,
  getDateFromStatusLog,
}) => {
  const allStatuses = [];

  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  allStatusesList.forEach((statusKey) => {
    if (statusesToIgnore.includes(statusKey)) return;

    const orderLog = orderStatuses.find((log) => log.status === statusKey);
    const giftLog = giftStatuses.find((log) => log.status === statusKey);
    const statusLog = orderLog || giftLog || { status: statusKey, date: null };

    const capitalizedStatus = statusKey.includes("recipient")
      ? capitalizeFirstLetter(t(statusByKey(statusLog.status)))
      : t(statusByKey(statusLog.status));

    let date;

    // Check if the statusKey is "giftLinkSentAt" for custom cake gifts
    if (statusKey === "giftlinksentAt") {
      date = getDateFromGiftStatusLog(giftStatuses, "giftLinkSentAt") || date;
    }

    // Check if the statusKey is "recipient_submitted" for the recipient-filled form status
    else if (statusKey === "recipient_submited") {
      date =
        getDateFromGiftStatusLog(giftStatuses, "recipientFilledForm") || date;
    }

    // For all other status types, fall back to orderStatuses date
    else {
      date = statusLog.date || getDateFromStatusLog(orderStatuses, statusKey);
    }

    allStatuses.push({
      ...statusLog,
      status: capitalizedStatus,
      message: t(statusMessageByKey(statusLog.status)),
      date: date,
    });
  });

  return allStatuses;
};

/**
 * First latter capital
 */
export const ucFirst = (str = "") => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};

/**
 * first latter capital
 */
export const capitalizeWord = (word = "") => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : "";
};

/**
 * clear local storage data
 */
export const clearLocalData = () => {
  let deviceId = getDeviceID();
  localStorage.clear();
  getDeviceID(deviceId);
};

/**
 * generate devvice id
 */
export const generateDeviceId = (length = 35) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let deviceId = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    deviceId += characters.charAt(randomIndex);
  }
  return deviceId;
};

/**
 * Get device Id
 * @param {*} setdeviceId
 * @returns
 */
export const getDeviceID = (setdeviceId = "") => {
  if (setdeviceId) {
    addDeleteGetLocalStorage(
      storageKeys?.DEVICE_ID,
      setdeviceId,
      "add",
      "single"
    );
  }
  let deviceId = addDeleteGetLocalStorage(storageKeys?.DEVICE_ID, {}, "get");
  if (!deviceId) {
    deviceId = generateDeviceId();
    addDeleteGetLocalStorage(storageKeys?.DEVICE_ID, deviceId, "add", "single");
  }
  return deviceId;
};

/**
 * get image url with base url
 */
export const getImage = (img) => {
  if (img) {
    if (img.includes("://")) {
      return img;
    }
  }
  return img ? `${process.env.REACT_APP_BASEURL}/uploads${img}` : "";
};

/**
 * get image url with base url
 */
export const setPrice = (price = 0) => {
  return price != 0 && price ? Number(price).toFixed(2) : 0.0;
};

/**
 * get image url with base url
 */
export const deliveryMethod = (method = "") => {
  let a = {
    home_office: "delivery",
    pickup_point: "pickup",
    buying_for_love: "gift",
  };
  return a[method] || method; // Use the method to access the value from the 'a' object
};

/**
 * get image url with base url
 */
export const isValidField = (value, type = "email") => {
  const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gim;
  // const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  const urlPattern =
    /^(?:(?:(?:https?|ftp):\/\/)?(?:www\.)?(?!localhost)[a-zA-Z0-9-]{1,63}(?:\.[a-zA-Z]{2,})+(?::\d+)?(?:\/[\w#!:.?+=&%@!\-\/]*)*)|(?:http:\/\/)?localhost(?::\d+)?(?:\/[\w#!:.?+=&%@!\-\/]*)*$/i;
  if (type == "email") {
    return value ? emailRegex.test(value) : false;
  }
  if (type == "url") {
    return value ? urlPattern.test(value) : false;
  }
  if (type == "mobile") {
    return value ? (value.length != 9 ? false : true) : false;
  }
  if (type == "otp") {
    return value ? (value.length != 4 ? false : true) : false;
  }
};

/**
 * getOff on product
 */
export const getOff = (a, s) => {
  if (a && s) {
    let total = a - s;
    if (total) {
      const percentage = (total / a) * 100;
      return percentage % 1 !== 0
        ? percentage.toFixed(2) + "%"
        : percentage + "%";
    }
  }
  return "";
};

/**
 * Product Price Filter
 */
export const ProductPriceFilter = () => {
  return [
    {
      min: 500,
      max: "+",
    },
    {
      min: 300,
      max: 399,
    },
    {
      min: 200,
      max: 299,
    },
    {
      min: 100,
      max: 199,
    },
  ];
};

/**
 * get current location
 */
async function getGeolocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const res = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocation is not supported in this browser."));
    }
  });
}

/**
 * get current location
 */
export const currentLocation = async () => {
  try {
    const res = await getGeolocation();
    return res;
  } catch (error) {
    console.error(error);
    return { lat: "", lng: "" }; // Handle errors gracefully
  }
};

/**
 * get user default address
 */
export const getDefautAddress = () => {
  return {
    address: "Riyadh Saudi Arabia",
    lattitude: Number(process.env.REACT_APP_DEFAULT_LAT),
    longitude: Number(process.env.REACT_APP_DEFAULT_LNG),
  };
};

/**
 * default address params
 */
export const defaultAddressParams = (data = {}) => {
  if (Object.keys(data).length == 0) {
    return {
      id: 0,
      full_name: getLoginDataByKey("fullname"),
      mobile_no: getMobileNo(getLoginDataByKey("mobileNo"), "number"),
      formatted_address: "",
      address: "",
      city: "",
      building_number: "",
      state: "",
      postal_code: "",
      country: "",
      floor_apartment: "",
      street_number: "",
      zone_number: "1",
      lattitude: Number(process.env.REACT_APP_DEFAULT_LAT),
      longitude: Number(process.env.REACT_APP_DEFAULT_LNG),
      darglat: "",
      draglnh: "",
    };
  }
  return {
    id: data?.id,
    full_name: data?.fullName,
    mobile_no: getMobileNo(data?.mobileNo, "number"),
    formatted_address: data?.address,
    address: data?.address,
    city: "tets",
    building_number: data?.buildingNumber,
    state: "test",
    postal_code: "test",
    country: "SA",
    zone_number: "1",
    lattitude: Number(data?.lattitude),
    longitude: Number(data?.longitude),
    darglat: data?.lattitude,
    draglnh: data?.longitude,
    floor_apartment: data?.floorApartment,
    street_number: data?.streetNumber,
  };
};

/**
 * check token expire expire
 */
export function isTokenExpired(token) {
  try {
    //return true;
    const decodedToken = jwtDecode(token);
    const expirationTime = new Date(decodedToken.exp * 1000);
    const currentTime = new Date();
    return currentTime > expirationTime;
  } catch (error) {
    // If there's an error decoding the token or it's expired, return true
    return true;
  }
}

/**
 *  Get login user detail
 */
export const getLoginDataByKey = (key) => {
  let data = loginData();
  if (data?.id) {
    if (key == "fullname") {
      return data ? data?.firstName + " " + data?.lastName : "";
    }
    return data ? data[key] : "";
  }
  return "";
};

/**
 * Date format change
 */
export const getCuurentDate = (formate = "ymd") => {
  const currentDate = new Date();
  if (formate == "ymd") {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return "";
};

/**
 *  Format Time
 */
function formatTime(hours, minutes) {
  const period = hours < 12 ? "AM" : "PM";
  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
  return `${formattedHours}:${minutes} ${period}`;
}

/**
 *  Minute to Hour conversion
 */
export const minutsToHour = (minutes = 0) => {
  minutes = minutes ? Number(minutes) : "";
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;
  return (
    hours.toString().padStart(2, "0") +
    ":" +
    remainingMinutes.toString().padStart(2, "0")
  );
};

/**
 *
 * @param {*} openTime
 * @param {*} closeTime
 * @returns
 */
export const formatTimeRange = (openTime, closeTime) => {
  // Parse the input times as Date objects
  if (openTime && closeTime) {
    const openHours = parseInt(openTime.substring(0, 2));
    const openMinutes = openTime.substring(3, 5);
    const closeHours = parseInt(closeTime.substring(0, 2));
    const closeMinutes = closeTime.substring(3, 5);

    const formattedOpenTime = formatTime(openHours, openMinutes);
    const formattedCloseTime = formatTime(closeHours, closeMinutes);

    const timeRange = `${formattedOpenTime} - ${formattedCloseTime}`;

    return timeRange;
  }
  return "";
};

/**
 *
 * @param {*} dateString
 * @returns
 */
export const getDayOfWeek = (dateString = "") => {
  if (!dateString) {
    dateString = new Date();
  }
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date(dateString);
  const dayIndex = date.getDay();
  return daysOfWeek[dayIndex].toLocaleLowerCase();
};

/**
 *
 * @param {*} date
 * @param {*} type
 * @returns
 */
export const changeDateFormat = (date = "", type = "dd/MM/yyyy") => {
  let dates = "";
  try {
    if (!date) return;
    date = date == "cdate" ? new Date() : new Date(date);
    const formattedDate = format(date, type);
    dates = formattedDate;
  } catch (e) {
    return "error";
  }
  return dates;
};

/**
 * date format change with time zone
 */
export const convertDateString = (inputDate = "", format = "dd/MM/yyyy") => {
  try {
    if (!inputDate) return;
    let date = inputDate == "cdate" ? new Date() : new Date(inputDate);
    const timeZone = "Asia/Riyadh";
    const formattedDate = formatInTimeZone(date, timeZone, format);
    return formattedDate;
  } catch (error) {
    return "error";
  }
};

/**
 * decode encode jwt
 */
export const jwtDecode = (value) => {
  return jwt_decode(value);
};

/**
 * decode encode jwt
 */
export const getMobileNo = (value, type = "code") => {
  try {
    if (value) {
      value = value.split("-");
      if (value?.length > 1) {
        return type == "code" ? value[0] : value[1];
      }
      return value[0];
    }
    return "";
  } catch (e) {
    return "wew43e";
  }
};

/**
 * check login or not
 */
export const loginData = () => {
  let token = addDeleteGetLocalStorage(storageKeys?.USER_TOKEN, {}, "get");
  let tokendata = token ? jwtDecode(token) : { id: 0 };
  if (Object.keys(tokendata).length !== 0) {
    return typeof tokendata?.user !== "undefined" ? tokendata?.user : { id: 0 };
  }
  return tokendata;
};

/**
 * check login or not
 */
export const getToken = async () => {
  let token = addDeleteGetLocalStorage(storageKeys?.USER_TOKEN, {}, "get");
  if (token) {
    //alert(isTokenExpired(token))
    if (isTokenExpired(token) == true) {
      try {
        const headers = {
          // Add any custom headers you need, e.g., Authorization token, API key, etc.
          Authorization: "Bearer " + token,
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        };
        const requestOptions = {
          method: "GET",
          headers: headers,
        };
        const response = await fetch(
          process.env.REACT_APP_BASEURL + ONBOARDING_APIS?.REFRESH_TOKEN,
          requestOptions
        );
        const jsonData = await response.json();
        if (jsonData?.status == "SUCCESS") {
          addDeleteGetLocalStorage(
            storageKeys?.USER_TOKEN,
            jsonData?.data?.token,
            "add",
            "single"
          );
          return jsonData?.data?.token;
        } else {
          clearLocalData();
          window.location.reload();
        }
      } catch (e) {
        clearLocalData();
        window.location.reload();
      }
    }
  }
  return token;
};

/**
 * check login or not
 */
export const refreshToken = async () => {
  let token = addDeleteGetLocalStorage(storageKeys?.USER_TOKEN, {}, "get");
  if (token) {
    try {
      const headers = {
        // Add any custom headers you need, e.g., Authorization token, API key, etc.
        Authorization: "Bearer " + token,
        "x-api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      };
      const requestOptions = {
        method: "GET",
        headers: headers,
      };
      const response = await fetch(
        process.env.REACT_APP_BASEURL + ONBOARDING_APIS?.REFRESH_TOKEN,
        requestOptions
      );
      const jsonData = await response.json();
      if (jsonData?.status == "SUCCESS") {
        addDeleteGetLocalStorage(
          storageKeys?.USER_TOKEN,
          jsonData?.data?.token,
          "add",
          "single"
        );
      }
    } catch (e) {}
  }
};

/**
 * check login or not
 */
export const checkLoginOrNot = () => {
  let token = addDeleteGetLocalStorage(storageKeys?.USER_TOKEN, {}, "get");
  let tokendata = token ? jwtDecode(token) : {};
  if (Object.keys(tokendata).length !== 0) {
    return typeof tokendata?.user !== "undefined" ? true : false;
  }
  return false;
};

/**
 * check login or not
 */
export const replaceKeyValue = (str, key, value, type = "str") => {
  if (type == "str") {
    return str ? str.replace(key, value) : "";
  }
  try {
    const array = JSON.parse(value.replace(/'/g, '"'));
    const keys = JSON.parse(key.replace(/'/g, '"'));
    keys.map((item, index) => {
      str = str.replace("[" + item + "]", array[index]);
    });
  } catch (e) {
    const keys = value ? JSON.parse(value.replace(/'/g, '"')) : "";
    console.log(keys);
  }
  return str;
};

/**
 * check login or not
 */
export const buildFullAddress = (address = {}) => {
  if (Object.keys(address).length !== 0) {
    if (address?.streetNumber != address?.address) {
      return [
        address?.streetNumber,
        address?.buildingNumber,
        address?.floorApartment,
        address?.address,
      ].join(", ");
    }
    return [
      address?.streetNumber,
      address?.buildingNumber,
      address?.floorApartment,
    ].join(", ");
  }
  return "";
};

/**
 * trim string
 */
export const strFormat = (inputStr, type) => {
  try {
    let all = type.split("|");
    if (all.includes("trim")) {
      inputStr = inputStr.trim();
    }

    if (all.includes("expiry_date") && inputStr) {
      //  inputStr = inputStr.replace(/\//g, "").substring(0, 2) +
      //   (inputStr.length > 2 ? '/' : '') +
      //   inputStr.replace(/\//g, "").substring(2, 4);
      inputStr = inputStr.replace(/\D/g, "");
      inputStr =
        inputStr.replace(/\//g, "").substring(0, 2) +
        (inputStr.length > 2 ? "/" : "") +
        inputStr.replace(/\//g, "").substring(2, 4);
    }

    if (all.includes("number")) {
      inputStr = inputStr.replace(/[^0-9]/g, "");
    }
    if (all.includes("text")) {
      inputStr = inputStr.replace(/\d/g, ""); // This regex replaces all digits (numbers) with an empty string.
    }
    for (const formatter of all) {
      const [command, ...args] = formatter.split(":");
      switch (command) {
        case "max":
          const maxLength = parseInt(args[0]);
          if (inputStr.length > maxLength) {
            inputStr = inputStr.slice(0, maxLength);
          }
          break;
        default:
          break;
      }
    }
    inputStr = inputStr ? inputStr.replace(/^\s+/, "") : "";
  } catch (e) {}
  return inputStr;
};

/**
 *  Check new-line/tab character or more than 4 consecutive spaces
 */
export const validateInputName = (inputValue, maxLength = 50) => {
  return strFormat(
    inputValue
      .replace(/[\n\t]/g, "")
      .replace(/\s{5,}/g, "    ")
      .replace(/\s+/g, " "),
    `max:${maxLength}`
  );
};

/**
 *  Check mobile id vlaid or not
 */
export const isValidMobile = (mobileNumber, t) => {
  if (mobileNumber.includes("98765")) return {};
  // const regex = /^[1-9]\d*$/;
  const regex = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
  const isValid = regex.test(mobileNumber);
  if (!isValid) {
    return { mobile_no: t("mobile_not_invalid") };
  }
  return {};
};

/**
 * trim string
 */
export const mobileWithCountryCode = (inputStr, code = "") => {
  if (!inputStr) {
    return "";
  }
  return code == "" && !inputStr.includes("+")
    ? "+966 " + inputStr
    : code + " " + inputStr;
};
/**
 * form validation
 */
export const validateForm = (data, type = "", t, cond) => {
  const errors = {};
  if (type == "addAddress") {
    let mobile_error = "";
    if (cond && !data?.mobile_no) {
      errors.mobile_no = t("mobile_no_length_error");
      mobile_error = 1;
    }
    if (cond && mobile_error == "") {
      if (data?.mobile_no.length != 9) {
        errors.mobile_no = t("mobile_no_length_error");
      }
    }
    if (cond && !data?.full_name) {
      errors.full_name = t("full_name_required");
    }
    if (!data?.street_number) {
      errors.street_number = t("street_number_required");
    }
    if (!data?.floor_apartment) {
      errors.floor_apartment = t("floor_apartment_required");
    }
    if (!data?.building_number) {
      errors.building_number = t("building_number_required");
    }
    if (!data?.address) {
      errors.address = t("address_is_required");
    }
  }

  if (type == "login") {
    let mobile_error = "";
    if (!data?.mobile_no) {
      errors.mobile_no = t("mobile_no_required");
      mobile_error = 1;
    }
    if (mobile_error == "") {
      if (data?.mobile_no.length != 9) {
        errors.mobile_no = t("mobile_no_length_error");
      }
    }
  }
  if (type == "signup") {
    let mobile_error = "";
    if (!data?.mobile_no) {
      errors.mobile_no = t("mobile_no_length_error");
      mobile_error = 1;
    }
    // if (!data?.email) {
    //   errors.email = t("please_enter_valid_email");
    // }
    if (data?.email) {
      if (data?.email.length > 0 && data?.email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (emailRegex.test(data?.email) == false) {
          errors.email = t("please_enter_valid_email");
        }
      }
    }
    if (data?.mobile_no.length != 9 && mobile_error == "") {
      errors.mobile_no = t("mobile_no_length_error");
    }
    let first_name_error = 0;
    if (data?.first_name.length == 0) {
      errors.first_name = t("first_name_required");
      first_name_error = 1;
    }
    if (data?.first_name.length > 50 && first_name_error != 1) {
      errors.first_name = t("first_name_length_error");
    }
    let last_name_error = 0;
    if (data?.last_name.length == 0) {
      errors.last_name = t("last_name_required");
      last_name_error = 1;
    }
    if (data?.last_name.length > 50 && last_name_error != 1) {
      errors.last_name = t("last_name_length_error");
    }
    if (data?.terms_and_condition == false) {
      errors.terms = t("terms_and_condition_required");
    }
  }
  if (type == "profile") {
    let first_name_error = 0;
    if (data?.first_name.length == 0) {
      errors.first_name = t("first_name_required");
      first_name_error = 1;
    }
    if (data?.first_name.length > 50 && first_name_error != 1) {
      errors.first_name = t("first_name_length_error");
    }
    let last_name_error = 0;
    if (data?.last_name.length == 0) {
      errors.last_name = t("last_name_required");
      last_name_error = 1;
    }
    if (data?.last_name.length > 50 && last_name_error != 1) {
      errors.last_name = t("last_name_length_error");
    }
    if (data?.email.length > 0 && data?.email) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (emailRegex.test(data?.email) == false) {
        errors.email = t("please_enter_valid_email");
      }
    }
    if (data?.dob) {
      //alert();
      if (changeDateFormat(data?.dob) == "error") {
        errors.dob = t("please_enter_valid_date");
      }
    }
  }
  if (type == "otp") {
    if (data?.otp.length != 4) {
      errors.otp = t("valid_otp_error");
    }
  }
  return errors;
};

/**
 * get default language
 */
export const getDefaultLanguage = (type = "small") => {
  let ldata = localStorage.getItem("jsahdjkanbn");
  ldata = ldata == "sd542s3ad2sa1d3iu748923DSF" ? "EN" : "AR";
  if (type != "small") {
    return ldata;
  }
  return ldata.toLowerCase();
};

/**
 * get locale data by key
 */
export const getdataByLangKey = (data, type = "", key = "", limit = "") => {
  let newData = "";
  if (!type) {
    type = getDefaultLanguage() ? getDefaultLanguage() : "en";
  }
  data?.forEach((item) => {
    if (item?.languageCode == type) newData = item[key];
  });
  if (limit && newData) {
    if (newData.length > limit) {
      newData = newData.substring(0, limit) + "...";
    }
  }
  return newData;
};

/**
 * check file extention
 */
export const checkFileExtention = (
  name,
  extention = "png",
  size = "",
  image = {}
) => {
  if (name) {
    if (size) {
      let sizes = size.split("-");
      const fileSize = image.size;
      const fileSizeKB = fileSize / 1024;
      if (fileSizeKB > 1024 * sizes[0] && sizes[1] == "MB") {
        return "You can send up to 2 MB in attachments.";
      }
    }

    let ext = extention.split("|");
    name = name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2);
    if (ext.includes(name.toLowerCase())) {
      return "";
    } else {
      return "Please upload files having extensions: " + ext.join(", ");
    }
  }
};

/**
 * category data by slug
 */
export const getCategoryDataBySlug = (getAllCatData = [], slug = "") => {
  let category = {
    name: "",
    list: [],
    id: 0,
    mainCategoryName: "",
    subcatid: 0,
  };
  for (let mainCategory of getAllCatData) {
    for (let subCategory of mainCategory?.subcategories) {
      if (subCategory?.slug.trim() == slug.trim()) {
        category = {
          id: subCategory?.id,
          subcatid: 0,
          list: subCategory?.subsubcategories || [],
          subcatslug: subCategory?.slug,
          mainCategoryName: getdataByLangKey(
            mainCategory?.categoryLocales,
            getDefaultLanguage(),
            "name"
          ),
          name: getdataByLangKey(
            subCategory?.categoryLocales,
            getDefaultLanguage(),
            "name"
          ),
        };
        return category;
      } else {
        for (let subsubCategory of subCategory?.subsubcategories) {
          if (subsubCategory?.slug.trim() == slug.trim()) {
            category = {
              list: subCategory?.subsubcategories,
              id: subsubCategory?.id,
              subcatid: subsubCategory?.parentId,
              subcatslug: subCategory?.slug,
              mainCategoryName: getdataByLangKey(
                mainCategory?.categoryLocales,
                getDefaultLanguage(),
                "name"
              ),
              name: getdataByLangKey(
                subCategory?.categoryLocales,
                getDefaultLanguage(),
                "name"
              ),
            };
            return category;
          }
        }
      }
    }
  }

  return category;
};

/**
 * get valid image url
 */
export const getValidImage = (url) => {
  const imageUrl = url ? url : "images/default.png";
  const imageUrlRegex = /\.(jpeg|jpg|gif|png)$/i;
  const isValidImageUrl = imageUrlRegex.test(imageUrl);
  return isValidImageUrl ? imageUrl : "images/default.png";
};

/**
 * check card type
 */
export const getCardType = (card_number) => {
  const MADA_BINS =
    "440647|440795|446404|457865|968208|457997|474491|636120|417633|468540|468541|468542|468543|968201|446393|409201|458456|484783|462220|455708|410621|455036|486094|486095|486096|504300|440533|489318|489319|445564|968211|410685|406996|432328|428671|428672|428673|968206|446672|543357|434107|407197|407395|412565|431361|604906|521076|529415|535825|543085|524130|554180|549760|968209|524514|529741|537767|535989|536023|513213|520058|558563|588848|588850|407520|968202|410834|968204|422817|422818|422819|428331|483010|483011|483012|589206|968207|419593|439954|530060|531196|420132|421141|588845|403024|968205|406136|42689700|605141|968203|242030|442463|22402030|22337902|22337986";
  const MEEZA_BINS =
    "507803[0-6][0-9]|507808[3-9][0-9]|507809[0-9][0-9]|507810[0-2][0-9]";
  var card_type = "";
  var card_validity = true;
  let image = "";
  var card_length = 0;
  if (card_number) {
    card_number = card_number.replace(/ /g, "").replace(/-/g, "");
    // Visa
    var visa_regex = new RegExp("^4[0-9]{0,15}$");
    // MasterCard
    var mastercard_regex = new RegExp("^5$|^5[0-5][0-9]{0,16}$");
    // American Express
    var amex_regex = new RegExp("^3$|^3[47][0-9]{0,13}$");
    //mada
    var mada_regex = new RegExp("/^" + MADA_BINS + "/", "m");
    //meeza
    var meeza_regex = new RegExp(MEEZA_BINS, "gm");
    if (card_number.match(mada_regex)) {
      card_type = "mada";
      image = "/images/credit-card/mada.png";
      card_length = 16;
    } else if (card_number.match(meeza_regex)) {
      card_type = "meeza";
      image = "/images/credit-card/meeza.png";
      card_length = 19;
    } else if (card_number.match(visa_regex)) {
      card_type = "visa";
      card_length = 16;
      image = "/images/credit-card/visa.png";
    } else if (card_number.match(mastercard_regex)) {
      card_type = "mastercard";
      card_length = 16;
      image = "/images/credit-card/Mastercard.png";
    } else if (card_number.match(amex_regex)) {
      image = "/images/credit-card/american.png";
      card_type = "amex";
      card_length = 15;
    } else {
      card_validity = false;
    }
    if (card_number.length < 15) {
      card_validity = false;
    }
  } else {
    card_validity = false;
  }
  return {
    card_length,
    card_type: card_type,
    card_validity: card_validity,
    image: image,
  };
};

/**
 * view like count
 */
export const formatViewCount = (count) => {
  // Check if the count is in the thousands range
  if (count >= 1000 && count < 1000000) {
    return (count / 1000).toFixed(1) + "k";
  }
  // Check if the count is in the millions range
  else if (count >= 1000000) {
    return (count / 1000000).toFixed(1) + "M";
  }
  // If the count is less than 1000, no formatting is needed
  else {
    return count ? count.toString() : count;
  }
};

/**
 *  Logic to scroll page to top
 */
export const scrollTopBody = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

/**
 * To generate html form for payment with card
 * @param {*} cardData
 * @returns
 */
export const paymentFormHtml = (cardData) => {
  return `
        <input type="hidden" NAME="service_command" value="TOKENIZATION" />
        <input type="hidden" NAME="language" value="en" />
        <input
          type="hidden"
          NAME="merchant_identifier"
          value=${cardData?.merchant_identifier}
        />
        <input type="hidden" NAME="card_number" value=${
          cardData?.card_number
        } />
        <input type="hidden" NAME="expiry_date" value=${cardData?.edate} />
        <input type="hidden" NAME="access_code" value=${
          cardData?.access_code
        } />
        <input type="hidden" NAME="signature" value=${cardData?.signature} />
        <input type="hidden" NAME="return_url" value=${cardData?.return_url} />
        ${
          cardData?.remember_me === "YES"
            ? '<input type="hidden" NAME="remember_me" value="YES" />'
            : ""
        }
        <input
          type="hidden"
          NAME="merchant_reference"
          value=${cardData?.order_id}
        />
        <input type="hidden" NAME="card_security_code" value=${cardData?.cvv} />
        <input
          type="hidden"
          NAME="card_holder_name"
          value=${cardData?.card_holder}
        />
        <input type="submit" value="" id="submit2" name=" " />`;
};

/**
 * To generate html form for payment with Tabby
 * @param {*} cardData
 * @returns
 */
export const paymentFormHtmlTabby = (tabbyData) => {
  return `
        <input type="hidden" NAME="command" value=${tabbyData?.command} />
        <input type="hidden" NAME="language" value=${tabbyData?.language} />
        <input type="hidden" NAME="amount" value=${tabbyData?.amount} />
        <input type="hidden" NAME="currency" value="SAR" />
        <input type="hidden" NAME="customer_email" value=${tabbyData?.customer_email} />
        <input type="hidden" NAME="payment_option" value=${tabbyData?.payment_option} />
        <input type="hidden" NAME="phone_number" value=${tabbyData?.phone_number}>
        <input type="hidden" NAME="order_description" value=${tabbyData?.order_description} />
        <input type="hidden" NAME="return_url" value=${tabbyData?.return_url} />
        <input type="hidden" NAME="merchant_identifier" value=${tabbyData?.merchant_identifier} />
        <input type="hidden" NAME="access_code" value=${tabbyData?.access_code} />
        <input type="hidden" NAME="signature" value=${tabbyData?.signature} />
        <input type="hidden" NAME="merchant_reference" value=${tabbyData?.order_id} />
        <input type="submit" value="" id="submit2" name=" " />`;
};

/**
 * To organise trancsaction data of user by month and year wise
 * @param {*} data
 * @param {*} key
 * @returns
 */
export const organizeDataByMonthAndYear = (data, key) => {
  const organizedData = {};

  data.forEach((transaction) => {
    const createdAt = new Date(transaction[key]);
    const monthYearKey = `${createdAt.toLocaleString("default", {
      month: "long",
    })} ${createdAt.getFullYear()}`;

    if (!organizedData[monthYearKey]) {
      organizedData[monthYearKey] = [];
    }

    organizedData[monthYearKey].push(transaction);
  });

  const result = Object.keys(organizedData).map((key) => ({
    [key]: organizedData[key],
  }));

  return result;
};

/** get cart data */
export const getCartData = () => {
  let dataCart = addDeleteGetLocalStorage(storageKeys.CART_DATA, {}, "get");
  let res = [];
  if (dataCart) {
    res = JSON.parse(dataCart);
  }
  return res;
};

export const limitTxt = (newData, limit = 0) => {
  if (limit && newData) {
    if (newData.length > limit) {
      newData = newData.substring(0, limit) + "...";
    }
  }
  return newData;
};

/**
 * Share on social media
 * @param {*} type
 * @param {*} images
 */
export const shareOnSocial = (type, images) => {
  if (type === "email") {
    let a = [
      "https://rabbitholebucket.s3.eu-north-1.amazonaws.com/afterActionqcImage1704283030948123951.png",
      "https://rabbitholebucket.s3.eu-north-1.amazonaws.com/afterActionqcImage1704283030948123951.png",
    ];
    let mailtoURL =
      "mailto:?subject=Images&body=Hello Dear, %0A  %0APlease check out these images %0A%0A" +
      a.join("%0A");
    window.open(mailtoURL, "_blank");
  }
};

/**
 * Get payment method
 * @param {*} trancsactionData
 * @returns
 */
export const getPaymentMethod = (trancsactionData) => {
  const isWalletUsed = trancsactionData?.walletUsed;
  const paymentMethod = trancsactionData?.method;
  const transactionDetails = trancsactionData?.transactionDetails;

  let result = {
    isWalletUsed:
      isWalletUsed && transactionDetails?.walletAmount > 0 ? true : false,
    isCardUsed: paymentMethod === "card" && transactionDetails?.paidAmount > 0,
    isTabbyUsed:
      paymentMethod === "tabby" && transactionDetails?.paidAmount > 0,
    isApplePayUsed:
      paymentMethod === "apple_pay" && transactionDetails?.paidAmount > 0,
    isOtherSourceUsed:
      paymentMethod != "wallet" && transactionDetails?.paidAmount > 0,
    type: "",
  };

  if (isWalletUsed) {
    if (paymentMethod === "wallet") {
      result.type = "paidviaWallet";
    } else if (paymentMethod === "card") {
      result.type = "paidviaBankAndWallet";
    } else if (paymentMethod === "tabby") {
      result.type = "paidviaTabbyAndWallet";
    } else if (paymentMethod === "apple_pay") {
      result.type = "paidviaApplePayAndWallet";
    }
  } else {
    if (paymentMethod === "card") {
      result.type = "paidviaBank";
    } else if (paymentMethod === "tabby") {
      result.type = "paidviaTabby";
    } else if (paymentMethod === "apple_pay") {
      result.type = "paidviaApplePay";
    }
  }
  return result;
};

/**
 * To convert string to title case
 */
export const toTitleCase = (str) => {
  return str.toLowerCase().replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
};

/**
 * To get ga cookie values
 */
export const getGACookieValues = () => {
  const cookies = document.cookie.split("; ");
  const result = {};
  cookies.forEach((cookie) => {
    const [name, value] = cookie.split("=");
    if (name === `_ga_${process.env.REACT_APP_GA_MEASUREMENT_ID}`) {
      result["session_id"] = value.split(".")[2];
    } else if (name === "_ga") {
      result["client_id"] = value.split(".").slice(2).join(".");
    }
  });
  return result;
};

export const sendPaymentStatusGaEvent = (
  status,
  transaction_for,
  userId,
  userDetails
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "payment_status",
      user_id: userId ? userId : userDetails?.guest_id,
      first_name: userDetails?.first_name || userDetails?.firstName,
      last_name: userDetails?.last_name || userDetails?.lastName,
      mobile_no: userDetails?.mobile_no || userDetails?.mobileNo,
      //   email: userDetails?.email || "",
      status: status,
      transaction_for: transaction_for,
    },
  });
};

export const sendWalletGaEvent = (amount) => {
  let loginUserData = loginData();
  TagManager.dataLayer({
    dataLayer: {
      event: "money_added_to_wallet",
      user_id: loginUserData?.id,
      first_name: loginUserData?.firstName,
      last_name: loginUserData?.lastName,
      mobile_no: loginUserData?.mobileNo,
      amount: amount,
    },
  });
};
