import React, { useState } from "react";
import { Button, Img, Text, Input } from "components";
import { useTranslation } from "react-i18next";
import { Rating } from "@mui/material";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import * as API from "../../utils/helper/Enum";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { changeTrigger } from "redux/reducers/trigger";
import * as CUSTOM from "../../utils/helper/custom";

const RateReview = (props) => {
  const { closepopup, productData } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState({});

  /**
   *  Add customer rating
   */
  const SubmitReview = async () => {
    inputData.order_id = productData?.order_id;
    inputData.product_id = productData?.product_id;
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.ORDER.ADD_RAITING}`,
        "post",
        { ...inputData, gaData: CUSTOM.getGACookieValues() },
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "succcess",
          })
        );
        dispatch(changeTrigger("refetchDetails"));
        closepopupout();
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
      dispatch(changeLoader(false));
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
  };

  const closepopupout = () => {
    closepopup(false);
    document.body.classList.remove("overflow-hidden");
    document.getElementById("header-box").classList.remove("relative");
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none m-auto w-fit h-fit xs:px-4">
        <div className="relative w-auto my-6 mx-auto max-w-[400px] min-w-[400px] xs:min-w-full xs:max-w-full">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white-A700 outline-none focus:outline-none overflow-hidden">
            <div className="flex flex-col items-center justify-start w-auto">
              <div className="flex flex-col items-center justify-start w-auto">
                <div className="flex flex-row items-start justify-end w-auto pt-4 pr-4 rtl:pr-0 rtl:pl-4 absolute right-0 rtl:right-auto rtl:left-0">
                  <Img
                    src="/images/modal-close.svg"
                    className="common-pointer h-6 w-6"
                    alt="close"
                    onClick={() => closepopupout()}
                  />
                </div>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-col items-center justify-start px-[30px] pt-8 pb-8 rounded-lg w-full before-pink-round before-pink-round-modal">
              <div className="flex flex-col justify-start w-full relative z-[5]">
                <div className="relative flex flex-row items-center justify-center w-full">
                  <img
                    className="h-16 w-16 rounded object-cover"
                    src={productData?.product_image || "/images/default.png"}
                    alt="moneyadded_Four"
                    loading="lazy"
                  />
                </div>
                <div className="flex flex-row items-center justify-start w-full mt-4">
                  <Text className="leading-[32.00px] text-base text-black-900 w-full text-center font-nunitomedium">
                    {productData?.productName}
                  </Text>
                </div>
                {productData?.is_add == 1 ? (
                  <>
                    <div className="flex flex-row items-center text-center w-full mt-4">
                      <Text className="leading-[32.00px] text-base text-black-900 w-full text-center">
                        <Rating
                          name="no-value"
                          value={inputData?.rating_number}
                          precision={0.5}
                          onChange={(e, newValue) => {
                            const newRating = newValue === null ? 0 : newValue; // Set to 0 if null (unrating)
                            setInputData({
                              ...inputData,
                              rating_number: newRating,
                            });
                          }}
                        />
                      </Text>
                    </div>
                    <div className="flex flex-col items-center mt-2 justify-start w-full">
                      <div className="w-full flex flex-col gap-x-5">
                        <Input
                          required
                          multiline
                          minRows={2}
                          label="Write your review here"
                          value={inputData?.review_note}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              review_note: e.target.value,
                            });
                          }}
                        />
                        {/* <div className="text-center text-black text-sm font-normal font-nunitomedium leading-normal mb-6">
                      We recently ordered a wedding cake and were extremely
                      pleased with the results. The design of the cake was
                      stunning, with beautiful decorations and colors. The taste
                      was equally impressive, moist, and flavorful with the
                      right amount of sweetness.
                    </div> */}
                      </div>
                      <div className="flex flex-row font-nunitomedium gap-3 items-center justify-start w-full mt-2 ">
                        <Button
                          className="bg-black-900 border border-black-900 cursor-pointer py-3.5 rounded text-base text-center text-white-A700 w-full"
                          hover={true}
                          hoverclass="bg-white-A700"
                          disabled={
                            !inputData?.rating_number ||
                            inputData?.rating_number == 0
                          }
                          onClick={(e) => {
                            SubmitReview();
                          }}
                        >
                          {t("Save")}
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-row items-center text-center w-full mt-4">
                      <Text className="leading-[32.00px] text-base text-black-900 w-full text-center">
                        <Rating
                          name="no-value"
                          value={productData?.reviewData?.ratingStar}
                          precision={0.5}
                          readOnly
                        />
                      </Text>
                    </div>
                    <div className="flex flex-col items-center mt-2 justify-start w-full">
                      <div className="w-full flex flex-col gap-x-5">
                        <div className="text-center text-black text-sm font-normal font-nunitomedium leading-normal mb-6">
                           {productData?.reviewData?.reviewNote}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-75 fixed inset-0 z-40 bg-black-900"
        onClick={() => closepopupout()}
      ></div>
    </>
  );
};

export { RateReview };
