import React, { useMemo, useState } from "react";
import { Button, Img, Line, Text } from "components";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { DeleteModal } from "popups/DeleteModal";
import globalRequest from "../../../../utils/global/globalRequest";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import * as CUSTOM from "../../../../utils/helper/custom";
import * as APIS from "../../../../utils/helper/Enum";
import { setSnackbar } from "redux/reducers/snackbar";
import { changeTrigger } from "redux/reducers/trigger";

const MyCustomCakesCustomcakecard = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [popupData, setPopupData] = useState({});
  const [DeleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSidebar, setOpen] = React.useState(false);
  const open = Boolean(anchorEl);

  /**
   * delete model popup open code
   */
  const handelsetDeleteModalOpen = () => {
    setDeleteModalOpen(true);
    setAnchorEl(null);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };

  /**
   * handle click edit delete dropdown)
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * handle close (edit delete dropdown)
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * open delete in drawer by cake id
   */
  const toggleDrawer = (id) => () => {
    if (openSidebar == false) {
      //setOpen(true);
      getCakeDetails(props?.data?.id);
    } else {
      setOpen(false);
    }
  };

  /**
   * get cake details by id
   */
  const getCakeDetails = useMemo(() => async (id, type = "") => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        `${APIS.CUSTOMCAKE.GET_CAKE_DETAILS}/${id}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        popupData = response?.data;
        setPopupData(response?.data);
        if (type == "placeOrder") {
          placeOrder();
        } else {
          setOpen(true);
        }
      }
    } catch (e) {}
    dispatch(changeLoader(false));
  });

  /**
   * Place order
   */
  const placeOrder = async () => {
    dispatch(changeLoader(true));
    try {
      const organizedData = popupData?.cakeDetails.map((cakeDetail) => {
        const groupId = cakeDetail.id;
        const items = cakeDetail.modifierGroupItems.map((item) => item.id);
        return { group_id: groupId, items: items };
      });
      let placeORderInput = {
        customCakeId: popupData?.id,
        device_type: "web",
        customCakeDetails: {
          cake_details: organizedData,
          upload_image_links: popupData.imageLinks,
          image_link: popupData.referenceLink,
        },
      };
 
      let response = await globalRequest(
        APIS?.CUSTOMCAKE?.PLACE_ORDER,
        "post",
        { ...placeORderInput, gaData: CUSTOM.getGACookieValues() },
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "success",
          })
        );
        props?.setValue(0);
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  /**
   * delete custom cake
   */
  const deleteConfirm = async (type = "no") => {
    if (type == "yes") {
      try {
        await globalRequest(
          `${APIS.CUSTOMCAKE.DELETE_CUSTOM_CAKE}/${props?.data?.id}`,
          "delete",
          {},
          {},
          true
        );
        dispatch(changeTrigger("getCustomCakeList"));
      } catch (e) {}
    }
  };

  /**
   * get item name
   */
  const getItemName = (items) => {
    let narray = [];
    items.map((item) => {
      narray.push(
        CUSTOM.getdataByLangKey(item?.modifierGroupItemLocales, "", "name")
      );
    });
    return narray.join(", ");
  };

  return (
    <>
      <div
        className={
          "bg-white-A700 border border-gray-300 border-solid flex flex-1 flex-col items-center justify-end p-4 rounded-lg w-full"
        }
      >
        <div className="flex flex-col items-center justify-start w-full">
          <div className="flex flex-col items-center justify-start w-full">
            <div className="h-[223px] relative w-[223px] md:w-full md:h-full">
              <Img
                className="h-[223px] m-auto object-cover rounded-lg w-[223px] md:w-full md:h-auto cursor-pointer"
                alt="rectangle17564"
                src={props?.userimage}
                onClick={toggleDrawer(props?.id)}
              />
              <div className="absolute bg-white-A700_28 flex flex-col h-7 items-center justify-start right-[2%] rounded-[50%] top-[4%] w-7">
                <IconButton
                  className="edit-delete-menu"
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() =>
                      navigate(`/design-my-custom-cake?id=${props?.data?.id}`)
                    }
                  >
                    <Img
                      src="/images/img_edit_black_900.svg"
                      className={"cursor-pointer w-5 h-5"}
                      alt="edit"
                    />
                    <Text
                      className="text-sm text-black-900 mx-2"
                      size="txtnunitoMedium16"
                    >
                      {t("edit")}
                    </Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handelsetDeleteModalOpen();
                    }}
                  >
                    <Img
                      src="/images/img_trash.svg"
                      className={"cursor-pointer w-5 h-5"}
                      alt="delete"
                    />
                    <Text
                      className="text-sm text-black-900 mx-2"
                      size="txtnunitoMedium16"
                    >
                      {t("delete")}
                    </Text>
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div
              className="flex flex-row items-center justify-between mt-3 w-full"
              onClick={toggleDrawer(true)}
            >
              <Text
                className="text-base text-black-900"
                size="txtnunitoMedium16"
              >
                {props?.esttime}
              </Text>
              <Text
                className="text-base text-gray-700 text-right"
                size="txtnunitoRegular16Gray700"
              >
                {props?.esttime1}
              </Text>
            </div>
            <div
              className="flex flex-row gap-[37px] items-center justify-between mt-[13px] w-full"
              onClick={toggleDrawer(true)}
            >
              <Text
                className="text-base text-black-900"
                size="txtnunitoMedium16"
              >
                {props?.estprice}
              </Text>
              <Text
                className="text-base text-gray-700 text-right"
                size="txtnunitoRegular16Gray700"
              >
                {props?.estprice1}
              </Text>
            </div>
            <Line className="bg-gray-300 h-px mt-5 w-full" />
          </div>
          <div
            className="flex flex-col h-auto items-center justify-center mt-3 rounded w-full cursor-pointer"
            onClick={() => getCakeDetails(props?.data?.id, "placeOrder")}
          >
            <Text
              className="text-center text-pink-800 text-sm w-auto "
              size="txtnunitoMedium14"
            >
              {props?.buttontext}
            </Text>
          </div>
        </div>
      </div>

      {DeleteModalOpen === true ? (
        <DeleteModal
          deleteTitle={t("deleteCake")}
          deleteParagraph={t("areYouSureYouWantToRemoveThisCakeQuestionMark")}
          closepopup={setDeleteModalOpen}
          deleteConfirm={deleteConfirm}
        />
      ) : null}

      <SwipeableDrawer
        anchor="right"
        open={openSidebar}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {openSidebar ? (
          <div className="w-[450px] xs:w-full px-[35px] sm:px-4 py-8 sm:py-4 before-pink-round">
            <div className="flex flex-row gap-3 items-center justify-start w-full mb-8 sm:mb-4">
              <Img
                className="h-6 w-6 cursor-pointer"
                src="/images/img_close_black_900.svg"
                alt="close"
                onClick={toggleDrawer(false)}
              />
              <Text
                className="text-2xl md:text-[22px] text-black-900 sm:text-xl"
                size="txtnunitoSemiBold24"
              >
                {t("customCake")}
              </Text>
            </div>
            <div className="mt-[10px] w-full ">
              <div className="bg-red-50_01 p-4 rounded w-full">
                <div className="flex flex-row items-start justify-between w-full">
                  <div className="flex items-center ">
                    <Img
                      className="h-6 w-6"
                      src="/images/img_clock_black_900.svg"
                      alt="clock"
                    />
                    <Text
                      className="text-base text-black-900 w-auto mx-2"
                      size="txtnunitoMedium16"
                    >
                      {t("estimatedPriceColon")}
                    </Text>
                  </div>
                  <Text
                    className="text-base text-gray-700 w-auto"
                    size="txtnunitoRegular16Gray700"
                  >
                    {t("sar")} {CUSTOM.setPrice(popupData?.price)}
                  </Text>
                </div>
                <div className="flex flex-row items-start justify-between mt-3 w-full">
                  <div className="flex items-center ">
                    <Img
                      className="h-6 w-6"
                      src="/images/img_television.svg"
                      alt="television"
                    />
                    <Text
                      className="text-base text-black-900 w-auto mx-2"
                      size="txtnunitoMedium16"
                    >
                      {t("estimatedPrepDotTimeColon")}
                    </Text>
                  </div>
                  <Text
                    className="text-base text-gray-700 w-auto"
                    size="txtnunitoRegular16Gray700"
                  >
                    {CUSTOM.minutsToHour(popupData?.prepTime)} {t("hrs")}
                  </Text>
                </div>
              </div>
              <div className="flex flex-col w-full mt-6 detail-box-height">
                {popupData?.cakeDetails.map((items, index) => {
                  return (
                    <div className="flex flex-col gap-1 items-start justify-start w-auto mb-4">
                      <Text
                        className="text-base text-black-900 w-auto"
                        size="txtnunitoMedium16"
                      >
                        {CUSTOM.getdataByLangKey(
                          items?.modifierGroupLocales,
                          "",
                          "name"
                        )}
                      </Text>
                      <Text
                        className="text-base text-gray-700 w-auto"
                        size="txtnunitoRegular16Gray700"
                      >
                        {getItemName(items?.modifierGroupItems)}
                      </Text>
                    </div>
                  );
                })}
                <Line className="bg-gray-300 h-px mt-2 mb-5 w-full" />
                <div className="flex flex-col gap-3.5 items-start justify-start w-full">
                  <Text
                    className="text-base text-black-900"
                    size="txtnunitoMedium16"
                  >
                    {t("referenceImageSlashLink")}
                  </Text>
                  {popupData?.imageLinks.length > 0 ? (
                    <div className="flex sm:flex-col flex-row gap-4 items-start justify-start w-auto sm:w-full">
                      {popupData?.imageLinks.map((imgItem, indexImg) => {
                        return (
                          <Img
                            className="h-[100px] md:h-auto object-cover rounded-lg w-[169px] sm:w-full"
                            key={`img-key-${popupData?.id}-${popupData?.indexImg}`}
                            src={imgItem}
                            alt="rectangle19120"
                          />
                        );
                      })}
                    </div>
                  ) : null}
                  {popupData?.referenceLink ? (
                    <p>
                      <a href={popupData?.referenceLink} target="_blank">
                        {popupData?.referenceLink}
                      </a>
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="bg-white-A700 flex flex-col items-center justify-start p-4 shadow-bs7 w-full absolute left-0 right-0 bottom-0">
                <Button
                  className="w-full"
                  variant={"FillBlack"}
                  size={"lg"}
                  onClick={(e) => {
                    placeOrder();
                  }}
                >
                  {t("placeOrder")}
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </SwipeableDrawer>
    </>
  );
};

export default MyCustomCakesCustomcakecard;
