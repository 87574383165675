import { useEffect, useMemo, useState } from "react";
import {
  Button,
  CheckboxCustom,
  Img,
  Input,
  List,
  Radio,
  Text,
} from "components";
import DesignMyOwnCakeQuestionOneHeader from "components/DesignMyOwnCakeQuestionOneHeader";
import Footer1 from "components/Footer1";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import StepProgress from "components/StepProgress";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import * as API from "../../utils/helper/Enum";
import { setSnackbar } from "../../redux/reducers/snackbar";
import * as CUSTOM from "../../utils/helper/custom";
import { InputAdornment } from "@material-ui/core";
import EmptyAll from "pages/EmptyList";
import { ImageCropper } from "popups/ImageCropper";
import DesignMyOwnCakeSummaryPage from "pages/DesignMyOwnCakeSummary";
import { useNavigate } from "react-router-dom";
import {
  addDeleteGetLocalStorage,
  storageKeys,
} from "../../utils/global/localData";
import { get } from "lodash";

const Box = styled.div`
  /* Add the selected styles when the component is selected */
  ${({ isSelected }) =>
    isSelected &&
    ` 

`}
`;
// border-color: #BD0043 !important;

const DesignMyOwnCakeQuestionOnePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [imageForCrop, setImageForCrop] = useState("");
  const [allSelectedImages, setAllSelectedImages] = useState([]);
  const [list, setList] = useState([]);
  const [settingData, setSettingData] = useState(null);
  const [reference, setReference] = useState("");
  const [textOnCake, setTextOnCake] = useState("");
  const [step, setStep] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [activeId, setActiveId] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [apiStatus, setApiStatus] = useState(false);
  const [allInputData, setAllInputData] = useState({});

  /**
   * get group name from list
   */
  const getGroupName = useMemo(
    () => (id) => {
      const groupdata = list.find((items) => items?.id === id);
      return groupdata
        ? CUSTOM.getdataByLangKey(groupdata?.modifierGroupLocales, "", "name")
        : "";
    },
    [list]
  );

  /**
   * get selected items total price
   */
  const getTotalPrice = () => {
    return selectedData.reduce((totalPrice, itemss) => {
      const groupdata = list.find((items) => items?.id === itemss?.group_id);
      if (groupdata) {
        totalPrice += groupdata.modifierGroupItems
          .filter((items) => itemss?.items.includes(items?.id))
          .reduce((subtotal, items) => subtotal + items?.price, 0);
      }
      totalPrice.toFixed(2);
      return totalPrice;
    }, 0);
  };

  /**
   * get estimate price
   */
  const getInputData = () => {
    return {
      cake_details: selectedData,
      upload_image_links: allSelectedImages,
      image_link: reference,
      text_on_cake: textOnCake,
      id: allInputData?.id ? allInputData?.id : "",
      textOnCake: textOnCake,
    };
  };

  const calculateGroupPrice = (selectedGroupData) => {
    const groupId = selectedGroupData?.group_id;
    const itemIds = selectedGroupData?.items;
    let groupPrice = 0;
    const modifierGroup = list.find((group) => group.id === groupId);
    if (modifierGroup) {
      itemIds.forEach((itemId) => {
        const item = modifierGroup.modifierGroupItems.find(
          (modifierItem) => modifierItem.id === itemId
        );
        if (item) {
          groupPrice += item.price;
        }
      });
    }
    return groupPrice;
  };

  /**
   * get question list
   */
  const getQuestionList = async () => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.CUSTOMCAKE.CUSTOM_CAKE_QUESTIONS_LIST}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        setList(response?.data);
        setSettingData(response?.setting_data);
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    setApiStatus(true);
  };

  const runAutoSroll = (index) => {
    const customCakeHeader = document.getElementById("custom-cake-header");
    const element = document.getElementById(`modifiergroup-${index + 1}`);
    if (element) {
      const headerHeight = customCakeHeader?.offsetHeight;
      const elementPosition = element?.offsetTop;
      const offsetPosition = elementPosition - headerHeight - 20;
      window.scrollTo({
        behavior: "smooth",
        top: offsetPosition,
      });
    }
  };

  /**
   * check and store id
   */
  const addCartItemsState = async (group, itemId, index) => {
    let existingModifierIndex = selectedData?.findIndex(
      (modifier) => modifier?.group_id === group?.id
    );
    let updatedSelectedData = [];
    if (existingModifierIndex === -1) {
      const newModifier = {
        group_id: group?.id,
        items: [itemId],
      };
      await setSelectedData((prevSelectedData) => {
        const newSelectedData = [...prevSelectedData, newModifier];
        updatedSelectedData = newSelectedData;
        existingModifierIndex = 0;
        return newSelectedData;
      });
    } else {
      if (checkedChekcbox(group, itemId) == false) {
        let min = group?.min;
        let max = group?.max;
        if (group?.type == "single") {
          max = 1;
        }
        let dataGetLength = selectedData[existingModifierIndex]?.items.length;
        let removeLastItem = false;
        if (dataGetLength == max && max != 0) {
          removeLastItem = true;
        }
        let newElement = itemId;
        const updatedData = selectedData.map((item) => {
          if (item.group_id === group?.id) {
            if (removeLastItem) {
              let itemArray = [...item.items];
              itemArray.pop();
              itemArray.push(newElement);
              return {
                ...item,
                items: itemArray,
              };
            } else {
              return {
                ...item,
                items: [...item.items, newElement],
              };
            }
          }
          return item;
        });
        updatedSelectedData = updatedData;
        setSelectedData(updatedData);
      } else {
        let elementToRemove = itemId;
        const updatedData = selectedData.map((item) => {
          if (item.group_id === group?.id) {
            return {
              ...item,
              items: item.items.filter((item) => item !== elementToRemove),
            };
          }
          return item;
        });
        updatedSelectedData = updatedData;
        setSelectedData(updatedData);
      }
    }
    let dataGetLength =
      updatedSelectedData[existingModifierIndex]?.items.length;
    let max = group?.max;
    if (group?.type == "single") {
      max = 1;
    }
    if (dataGetLength == max && max != 0) {
      runAutoSroll(index);
    }
  };

  /**
   * check checked checkbox
   */
  const checkedChekcbox = (group, itemId) => {
    const existingModifierIndex = selectedData?.findIndex(
      (modifier) => modifier?.group_id === group?.id
    );
    if (existingModifierIndex !== -1) {
      let dataGetLength = selectedData[existingModifierIndex]?.items;
      if (dataGetLength.includes(itemId)) {
        return true;
      }
    }
    return false;
  };

  /**
   * check disabled button
   */
  const checkDisableButton = () => {
    let result = false; 
    if ((allSelectedImages.length == 0 && !reference) ||
    (reference && !CUSTOM.isValidField(reference, "url"))) {
      result = true;
    }
    for (let group of list) {
      let min = group?.min;
      if (group?.type == "single") {
        min = 1;
      }
      let existingModifierIndex = selectedData?.findIndex(
        (modifier) => modifier?.group_id === group?.id
      );
      let totalItems = 0;
      if (existingModifierIndex === -1) {
        if (totalItems < min) {
           result = true;
        }
      } else {
        totalItems = selectedData[existingModifierIndex].items.length;
        if (totalItems < min) {
          result = true;
        }
      }
    }
    return result;
  };

  /**
   * handle crop image
   */
  const handleImageChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let imageError = CUSTOM.checkFileExtention(
      file.name,
      "png|jpg|jpeg",
      "",
      ""
    );
    if (imageError) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: imageError,
          snackbarState: "error",
        })
      );
      document.getElementById("image-upload-field").value = "";
      return false;
    }
    let reader = new FileReader();
    reader.onloadend = () => {
      getCompressedImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    document.getElementById("image-upload-field").value = "";
  };

  const getCompressedImage = (base64Url) => {
    const outputQuality = 0.9; // Initial quality (adjust as needed)
    const maxSizeInBytes = 0.7 * 1024 * 1024; // 1MB

    const img = new Image();
    img.src = base64Url;
    img.onload = () => {
      // Create a canvas and draw the image on it
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      const tryCompress = (quality) => {
        return new Promise((resolve) => {
          canvas.toBlob(
            (blob) => {
              if (blob.size <= maxSizeInBytes || quality <= 0.1) {
                resolve(blob);
              } else {
                tryCompress(quality - 0.1).then(resolve);
              }
            },
            "image/jpeg",
            quality
          );
        });
      };

      tryCompress(outputQuality).then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          getCroppedImage(base64String);
        };
        reader.readAsDataURL(blob);
      });
    };
  };

  /**
   * get cake details by id
   */
  const getCakeDetailsById = async (id) => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.CUSTOMCAKE.GET_CAKE_DETAILS}/${id}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        const organizedData = response?.data?.cakeDetails.map((cakeDetail) => {
          const groupId = cakeDetail.id;
          const items = cakeDetail.modifierGroupItems.map((item) => item.id);
          return { group_id: groupId, items: items };
        });
        setSelectedData(organizedData);
        setTextOnCake(response?.data?.textOnCake);
        setReference(response?.data?.referenceLink);
        setAllSelectedImages(response?.data?.imageLinks);
        setAllInputData({ ...allInputData, id: response?.data?.id });
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const getCroppedImage = async (image) => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.CUSTOMCAKE.UPLOAD_IMAGE}`,
        "post",
        { image: image },
        {},
        true
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        const updatedImages = [...allSelectedImages, response?.data];
        setAllSelectedImages(updatedImages);
        setImageForCrop("");
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
  };

  /**
   * save data
   */
  const saveData = async (type) => {
    try {
      dispatch(changeLoader(true));
      if (type == "saveforlatter") {
        try {
          /**
           * Data for segment
           */
          let response = await globalRequest(
            API?.CUSTOMCAKE?.SAVE_FOR_LATTER,
            "post",
            {...getInputData(), gaData: CUSTOM.getGACookieValues()},
            {},
            true
          );
          response = response?.data;
          if (response?.status == "SUCCESS") {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarMessage: response?.message,
                snackbarState: "success",
              })
            );
            addDeleteGetLocalStorage(
              storageKeys.ORDER_TAB,
              "mycustomcake",
              "add",
              "single"
            );
            navigate("/my-order");
          } else {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarMessage: response?.message,
                snackbarState: "error",
              })
            );
          }
        } catch (e) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: e?.message,
              snackbarState: "error",
            })
          );
        }
        dispatch(changeLoader(false));
      } else {
        let placeORderInput = {
          customCakeId: 0,
          device_type: "web",
          customCakeDetails: {
            cake_details: getInputData()?.cake_details,
            upload_image_links: getInputData()?.upload_image_links,
            image_link: getInputData()?.image_link,
            text_on_cake: getInputData()?.textOnCake,
          },
        };
        try {
          let response = await globalRequest(
            API?.CUSTOMCAKE?.PLACE_ORDER,
            "post",
            { ...placeORderInput, gaData: CUSTOM.getGACookieValues() },
            {},
            true
          );
          response = response?.data;
          if (response?.status == "SUCCESS") {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarMessage: response?.message,
                snackbarState: "success",
              })
            );
            navigate("/my-order");
          } else {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarMessage: response?.message,
                snackbarState: "error",
              })
            );
          }
        } catch (e) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: e?.message,
              snackbarState: "error",
            })
          );
        }
        dispatch(changeLoader(false));
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
  };

  /**
   * update Data
   */
  const updateData = async (type) => {
    dispatch(changeLoader(true));
    if (type == "saveforlatter") {
      try {
        let response = await globalRequest(
          API?.CUSTOMCAKE?.UPDATE_CUSTOM_CAKE,
          "PUT",
          {...getInputData(), text_on_cake: getInputData()?.textOnCake},
          {},
          true
        );
        response = response?.data;
        if (response?.status == "SUCCESS") {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: response?.message,
              snackbarState: "success",
            })
          );
          addDeleteGetLocalStorage(
            storageKeys.ORDER_TAB,
            "mycustomcake",
            "add",
            "single"
          );
          navigate("/my-order");
        } else {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: response?.message,
              snackbarState: "error",
            })
          );
        }
      } catch (e) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: e?.message,
            snackbarState: "error",
          })
        );
      }
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    setTotalPrice(getTotalPrice());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  /**
   * get question list
   */
  useEffect(() => {
    getQuestionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * empty image field when close modal crop popup
   */
  useEffect(() => {
    if (!imageForCrop) {
      const imageUploadField = document.getElementById("image-upload-field");
      if (imageUploadField) {
        imageUploadField.value = "";
      }
    }
  }, [imageForCrop]);

  /**
   * change steps
   */
  useEffect(() => {
    if (activeId == 0) {
      setStep("");
    }
  }, [activeId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (id && list.length > 0) {
      getCakeDetailsById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <>
      {
        //step != "getEstimate" ? (
        <>
          <div className="bg-gray-50_02 flex flex-col items-center justify-start mx-auto w-full">
            <DesignMyOwnCakeQuestionOneHeader
              className="bg-white-A700 flex flex-row items-center justify-start p-1.5 shadow-bs w-full fixed top-0 left-0 z-10"
              id="custom-cake-header"
              p0={Number(activeId) + 1}
              p13={list.length + 1}
              exittext={t("exit")}
              title={t("designYourOwnCake")}
              allInputData={allInputData}
            />
            {/* <div className="h-1 overflow-hidden relative w-full mt-[64px]">
            <StepProgress
              totalSteps={list.length}
              activeStep={Number(activeId) + 1}
            />
          </div> */}
            <div className="flex flex-row sm:flex-col gap-[30px] items-start justify-start mx-auto max-w-[1110px] w-full md:px-4 mt-[74px] py-10">
              <div className="w-full max-w-[652px] sm:max-w-full">
                <div className="content-area flex-1 overflow-y-auto w-full flex flex-col gap-5">
                  {list.length == 0 && apiStatus ? (
                    <EmptyAll type="custom-cake" />
                  ) : null}
                  {Number(activeId) + 1 <= list.length ? (
                    <>
                      {list?.map((items, index) => {
                        //    if (activeId == index) {
                        return (
                          <div
                            className="bg-white-A700 px-9 pt-5 pb-8 w-full xs:px-4 rounded-lg"
                            key={`mainindexing-${index}`}
                            id={`modifiergroup-${index}`}
                          >
                            <Text
                              className="text-2xl md:text-[22px] text-black-900 sm:text-xl"
                              size="txtnunitoSemiBold24"
                            >
                              {CUSTOM.getdataByLangKey(
                                items.modifierGroupLocales,
                                "",
                                "name"
                              )}
                            </Text>
                            <Text
                              className="mt-[7px] text-gray-700 text-sm"
                              size="txtnunitoRegular14"
                            >
                              {items?.type == "single" ? (
                                <>
                                  {t("chooseAny")} 1 {t("optionSmallLetter")}
                                </>
                              ) : null}

                              {items?.type == "multiple" &&
                              items?.min == 0 &&
                              items?.max == 0 ? (
                                <>{t("chooseMutipleOptions")}</>
                              ) : null}
                              {items?.type == "multiple" &&
                              items?.min == 0 &&
                              items?.max != 0 ? (
                                <>
                                  {CUSTOM.replaceKeyValue(
                                    t("chooseMaxOption"),
                                    "[VALUE]",
                                    items?.max
                                  )}
                                </>
                              ) : null}
                              {items?.type == "multiple" &&
                              items?.min != 0 &&
                              items?.max == 0 ? (
                                <>
                                  {CUSTOM.replaceKeyValue(
                                    t("chooseMinOption"),
                                    "[VALUE]",
                                    items?.min
                                  )}
                                </>
                              ) : null}

                              {items?.type == "multiple" &&
                              items?.min != 0 &&
                              items?.max != 0 ? (
                                <>
                                  {CUSTOM.replaceKeyValue(
                                    t("cooseMinMAxOption"),
                                    "['MIN','MAX']",
                                    "[" + items?.min + "," + items?.max + "]",
                                    "array"
                                  )}
                                </>
                              ) : null}
                            </Text>
                            <List
                              className="flex flex-col font-nunitoregular items-start mt-6 w-full"
                              orientation="vertical"
                            >
                              {items?.modifierGroupItems.map(
                                (item, indexing) => {
                                  return (
                                    <Box
                                      key={`indexing-${indexing}`}
                                      className="bg-white-A700 border-b border-gray-300 border-solid flex flex-row items-center justify-between py-4 w-full cursor-pointer first:pt-0 last:pb-0 last:border-none"
                                      isSelected={checkedChekcbox(
                                        items,
                                        item?.id
                                      )}
                                      onClick={() =>
                                        addCartItemsState(
                                          items,
                                          item?.id,
                                          index
                                        )
                                      }
                                    >
                                      <div className="flex items-center">
                                        {items?.type == "single" ? (
                                          <Radio
                                            className="mr-0 rtl:mr-0 rtl:ml-0 w-5 h-5"
                                            checked={checkedChekcbox(
                                              items,
                                              item?.id
                                            )}
                                            value={item?.id}
                                          />
                                        ) : (
                                          <CheckboxCustom
                                            className="ml-0 mr-0 rtl:mr-0 rtl:ml-0 w-5 h-5 "
                                            checked={checkedChekcbox(
                                              items,
                                              item?.id
                                            )}
                                            value={item?.id}
                                            inputClassName="w-5 h-5 !p-0"
                                          />
                                        )}
                                        <Text
                                          className="mx-[14px] text-basetext-black-900"
                                          size="txtnunitoRegular16"
                                        >
                                          {CUSTOM.getdataByLangKey(
                                            item?.modifierGroupItemLocales,
                                            "",
                                            "name"
                                          )}
                                        </Text>
                                      </div>
                                      <div className="flex flex-row gap-2 items-center justify-center mr-[3px]">
                                        {/* <Text
                        className="line-through text-gray-700 text-right text-sm"
                        size="txtnunitoRegular14"
                      >
                        {item?.actualPrice}
                      </Text> */}
                                        <Text
                                          className="text-base text-black-900 text-right"
                                          size="txtnunitoRegular16"
                                        >
                                          {t("sar")} {item?.price}
                                        </Text>
                                      </div>
                                    </Box>
                                  );
                                }
                              )}
                            </List>
                            {/* <Footer1
                            className="bg-white-A700 flex font-nunitomedium items-center justify-center mt-[32px] md:px-4 shadow-bs w-full fixed bottom-0 left-0 z-10"
                            activeId={index}
                            totalPrice={totalPrice}
                            setActiveId={setActiveId}
                            disableButton={checkDisableButton(items)}
                          /> */}
                          </div>
                        );
                        //  }
                      })}
                    </>
                  ) : null}
                </div>
                {
                  //list.length < activeId + 1 &&
                  list.length ? (
                    <>
                      <div
                        className="bg-white-A700 px-9 pt-5 pb-8 w-full xs:px-4 mt-5 rounded-lg"
                        id={`modifiergroup-${list?.length}`}
                      >
                        <div>
                          <Text
                            className="text-2xl md:text-[22px] text-black-900 sm:text-xl text-left"
                            size="txtnunitoSemiBold24"
                          >
                            {t(
                              "whatKindOfCakeWouldYouLikeItToResembleQuestionMark"
                            )}
                          </Text>
                          <div className="flex flex-row font-nunitoregular gap-2 items-start justify-start mt-[5px] w-full">
                            <Text
                              className="text-gray-700 text-sm"
                              size="txtnunitoRegular14"
                            >
                              {t("youCanSearchCakesOn")}
                            </Text>
                            <Img
                              className="h-5 w-5"
                              src={
                                settingData?.logoLink
                                  ? settingData?.logoLink
                                  : "/images/img_pinterestsvg.svg"
                              }
                              alt="pinterestsvg"
                            />
                            <a
                              href={
                                settingData?.searchLink
                                  ? settingData?.searchLink
                                  : "https://in.pinterest.com/search/pins/?q=custom%20cake&rs=typed"
                              }
                              className="text-gray-700 text-sm underline"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Text>{t("pinterestDot")}</Text>
                            </a>
                          </div>
                          {allSelectedImages.length < 5 && (
                            <label className="bg-red-50_01 flex flex-col font-nunitoregular items-center justify-start mt-7 p-5 rounded  min-w-[540px] sm:min-w-full w-full cursor-pointer ">
                              <Img
                                className="h-9 w-9"
                                src="/images/img_upload.svg"
                                alt="upload"
                              />
                              <Text
                                className="mt-2.5 text-base text-black-900"
                                size="txtnunitoRegular16"
                              >
                                {t("uploadImage")}
                              </Text>
                              <Text
                                className="my-[7px] text-center text-gray-700 text-xs"
                                size="txtnunitoRegular12Gray700"
                              >
                                {t(
                                  "uploadCakeImagesInPNGSlashJPGSlashJPEGFormatDot"
                                )}
                              </Text>
                              <input
                                type="file"
                                accept="image/jpeg, image/jpg, image/png"
                                id="image-upload-field"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  handleImageChange(e);
                                }}
                              />
                            </label>
                          )}

                          {allSelectedImages.length > 0 && (
                            <List
                              className="flex-wrap flex-row gap-4 flex justify-start mt-3  w-full"
                              orientation="horizontal"
                            >
                              {allSelectedImages.map((items, index) => {
                                return (
                                  <div className="h-[100px] w-[100px] relative w-full">
                                    <Img
                                      className="h-[100px] m-auto object-cover rounded-lg w-full"
                                      src={
                                        items.includes("://")
                                          ? items
                                          : `${process.env.REACT_APP_BASEURL}${items}`
                                      }
                                      alt="rectangle19120"
                                    />
                                    <Img
                                      className="absolute h-5 right-[2%] top-[4%] w-5 cursor-pointer"
                                      src="/images/img_close_gray_900_02.svg"
                                      alt="close"
                                      onClick={(e) => {
                                        const updatedImages =
                                          allSelectedImages.filter(
                                            (_, i) => i !== index
                                          );
                                        setAllSelectedImages(updatedImages);
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </List>
                          )}
                          <Text
                            className="mt-[33px] text-gray-700 text-sm text-left"
                            size="txtnunitoRegular14"
                          >
                            {t("orPasteAReferenceLinkForYourCustomCake")}
                          </Text>
                          <div className="mt-5  w-full">
                            <Input
                              placeholder="Paste a reference link"
                              value={reference}
                              onChange={(e) => {
                                setReference(
                                  CUSTOM.strFormat(
                                    e.target.value,
                                    "MAX:5555555"
                                  )
                                );
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Img
                                    className="h-6 w-6 cursor-pointer"
                                    src="/images/insert_link.svg"
                                    alt="play"
                                  />
                                </InputAdornment>
                              }
                            ></Input>
                          </div>

                          <Text
                            className="mt-[5px] text-gray-700 text-sm text-left"
                            size="txtnunitoRegular14"
                          >
                            {t("nameoncake")}
                          </Text>
                          <div className="mt-5  w-full">
                            <Input
                              placeholder={t("nameoncake")}
                              value={textOnCake}
                              onChange={(e) => {
                                setTextOnCake(e.target.value);
                              }}
                              wrapperClass="!mb-0"
                            ></Input>
                          </div>
                        </div>
                      </div>
                      {/* <Footer1
                    className="bg-white-A700 flex font-nunitomedium items-center justify-center mt-[32px] md:px-4 shadow-bs w-full"
                    setActiveId={setActiveId}
                    disableButton={
                      (allSelectedImages.length == 0 || !reference) ||
                      (reference && !CUSTOM.isValidField(reference, "url"))
                    }
                    activeId={activeId}
                    showEstimaebutton={1}
                    getEstimate={getEstimate}
                  /> */}
                    </>
                  ) : null
                }
              </div>
              <div className="w-full max-w-[428px] sm:max-w-full">
                <div className="bg-white-A700 w-full px-9 pt-8 pb-10 xs:px-4 rounded-lg">
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-row items-center justify-between">
                      <Text className="text-black-900 whitespace-nowrap font-medium font-nunitoregular text-sm leading-5">
                        {t("orderSummary")}
                      </Text>
                    </div>
                    <div className="flex flex-col gap-4">
                      {selectedData?.map((items, index) => {
                        if (items?.items?.length == 0) return null;
                        return (
                          <div
                            key={index}
                            className="flex flex-row items-center justify-between"
                          >
                            <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-5 text-start">
                              {getGroupName(items?.group_id)}
                            </Text>
                            <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                              {t("sar")} {calculateGroupPrice(items)}
                            </Text>
                          </div>
                        );
                      })}
                      <div className="border-b border-black-900"></div>
                      <div className="bg-white-A700 flex flex-row items-start justify-between w-full">
                        <Text className="text-base text-black-900 font-nunitoregular">
                          {t("totalAmount")}
                        </Text>
                        <Text className="text-base text-black-900 text-right font-nunitomedium">
                          {t("sar")} {CUSTOM.setPrice(totalPrice)}
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex xs:flex-col flex-row gap-4 items-center justify-center mt-9 w-full">
                    {allInputData?.id ? (
                      <>
                        <Button
                          className="border border-black-900 border-solid cursor-pointer h-12 py-2.5 rounded text-base text-black-900 text-center w-[200px]"
                          disabled={checkDisableButton()}
                          onClick={(e) => {
                            updateData("saveforlatter");
                          }}
                        >
                          {t("update")}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          className="border border-black-900 border-solid cursor-pointer h-12 py-2.5 rounded text-base text-black-900 text-center w-full"
                          disabled={checkDisableButton()}
                          onClick={(e) => {
                            saveData("saveforlatter");
                          }}
                        >
                          {t("saveForLater")}
                        </Button>
                        <Button
                          className="bg-black-900 cursor-pointer h-12 py-2.5 rounded text-base text-center text-white-A700 w-full"
                          disabled={checkDisableButton()}
                          onClick={(e) => {
                            saveData("placeOrder");
                          }}
                        >
                          {t("placeOrder")}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {imageForCrop && (
              <ImageCropper
                image={imageForCrop}
                allSelectedImages={allSelectedImages}
                setAllSelectedImages={setAllSelectedImages}
                setImageForCrop={setImageForCrop}
              ></ImageCropper>
            )}
          </div>
          {/* <DesignMyOwnCakeSummaryPage
          setActiveId={setActiveId}
          allInputData={allInputData}
          isEdit={true}
          questionsData={list}
        ></DesignMyOwnCakeSummaryPage> */}
        </>
        //   )
      }
    </>
  );
};

export default DesignMyOwnCakeQuestionOnePage;
